<template>
  <!--<section class="pc" v-if="!isMobile" style="min-height: 800px;">-->
  <!--  <div class="game_contents" style="min-height: 500px;">-->
  <!--    <table style="width: 86%;" >-->
  <!--      <tbody>-->
  <!--      <tr>-->
  <!--    왼쪽사이드바      -->
  <!--        <SideBar :check-block-game="checkBlockGame"></SideBar>-->
  <!-- left -->
  <!--        <td style="padding:0 10px 0 10px; width: 90%;" valign="top">-->
  <!--          <SelectedGame :origin-list="originList" :game="game" :add-cart="addCart" :check-cart="checkCart" :check-block-game="checkBlockGame" :block-type-setting="blockTypeSetting"></SelectedGame>-->
  <!--        </td>-->
  <!--        <td class="game_right_wrap" v-if="!sticky" valign="top" style="position: fixed; width: 10%;" >-->
  <!--          <NewCart :toggle-stick="toggleStick" :fixed="false"></NewCart>-->
  <!--        </td>-->
  <!--        <td class="game_right_wrap" v-if="sticky" valign="top" style="position: absolute; width: 10%;">-->
  <!--          <NewCart :toggle-stick="toggleStick" :fixed="false"></NewCart>-->
  <!--        </td>-->
  <!--      </tr>-->
  <!--      </tbody>-->
  <!--    </table>-->
  <!--  </div>-->
  <!--</section>-->
  <div>
    <div v-if="isLoading">
      <div class="header2" v-if="game">
        <div class="left">
          <router-link
            :to="{ name: '스포츠-엘스포츠(인플레)-목록' }"
            class="btn-prev"
          ></router-link>
        </div>
        <div class="bet-h1">
          <img
            v-if="game.sport.icon"
            :src="game.sport.icon"
            style="width: 20px"
          />
          <img
            v-if="game.league.icon"
            :src="game.league.icon"
            style="width: 30px; height: 15px"
          />
          <p>
            {{ game.homeTeam | teamName }}
            <span>vs</span>
            {{ game.awayTeam | teamName }}
          </p>
        </div>
      </div>
      <div
        class="live-info"
        :class="{
          baseball: game.sportId === 154914,
          hockey: game.sportId === 35232,
          basketball: game.sportId === 48242,
          volleyball: game.sportId === 154830,
          rugby: game.sportId === 131506,
          lol: game.sportId === 687890,
        }"
      >
        <div class="tit">{{ game.league | leagueName }}</div>
        <dl>
          <dt v-if="game.sportId === 6046">
            {{ game | currentPeriod3("period") }} {{ game.currentTime }}
            {{ game[`set${game.currentPeriod}Score`] }}
          </dt>
          <dt v-else>
            {{ game | currentPeriod3("period") }}
            {{ game[`set${game.currentPeriod}Score`] }}
          </dt>
          <!--   스코어     -->
          <dd>
            <template v-if="game.sportId === 154914">
              <span
                v-for="l in 9"
                :key="'SportDetailPeriod1' + l"
                class="score"
              >
                {{ l }}
              </span>
            </template>
            <template v-else-if="game.sportId === 6046">
              <span
                v-for="l in 2"
                :key="'SportDetailPeriod1' + l"
                class="score"
              >
                {{ l }}
              </span>
            </template>
            <template v-else-if="game.sportId === 154830">
              <span
                v-for="l in 5"
                :key="'SportDetailPeriod1' + l"
                class="score"
              >
                {{ l }}
              </span>
            </template>
            <template v-else-if="game.sportId === 35232">
              <span
                v-for="l in 3"
                :key="'SportDetailPeriod1' + l"
                class="score"
              >
                {{ l }}
              </span>
            </template>
            <template
              v-else-if="game.sportId === 48242 || game.sportId === 131506"
            >
              <span
                v-for="l in 4"
                :key="'SportDetailPeriod1' + l"
                class="score"
              >
                {{ l }}
              </span>
            </template>
            <template v-else>
              <span
                v-for="l in Number(game.currentPeriod)"
                :key="'SportDetailPeriod1' + l"
                class="score"
              >
                {{ l }}
              </span>
            </template>
            <span class="score">T</span>
          </dd>
        </dl>
        <dl>
          <dt>
            {{ game.homeTeam | teamName }}
          </dt>
          <dd>
            <!--   스코어    -->
            <template v-if="game.sportId === 154914">
              <span
                v-for="l in 9"
                :key="'SportDetailPeriod1' + l"
                class="score"
              >
                {{ game[`set${l}Score`]?.split(":")[0] }}
              </span>
            </template>
            <template v-else-if="game.sportId === 3">
              <span
                v-for="l in 4"
                :key="'SportDetailPeriod1' + l"
                class="score"
              >
                {{ game[`set${l}Score`]?.split(":")[0] }}
              </span>
            </template>
            <template v-else>
              <span
                v-for="l in Number(game.currentPeriod)"
                :key="'SportDetailPeriod1' + l"
                class="score"
              >
                {{ game[`set${l}Score`]?.split(":")[0] }}
              </span>
            </template>

            <span class="yellow score" style="width: 15px">{{
              game.homeResult
            }}</span>
          </dd>
        </dl>
        <dl>
          <dt>
            {{ game.awayTeam | teamName }}
          </dt>
          <dd>
            <template v-if="game.sportId === 154914">
              <span
                v-for="l in 9"
                :key="'SportDetailPeriod2' + l"
                class="score"
              >
                {{ game[`set${l}Score`]?.split(":")[1] }}
              </span>
            </template>
            <template v-else-if="game.sportId === 3">
              <span
                v-for="l in 4"
                :key="'SportDetailPeriod1' + l"
                class="score"
              >
                {{ game[`set${l}Score`]?.split(":")[1] }}
              </span>
            </template>
            <template v-else>
              <span
                v-for="l in Number(game.currentPeriod)"
                :key="'SportDetailPeriod1' + l"
                class="score"
              >
                {{ game[`set${l}Score`]?.split(":")[1] }}
              </span>
            </template>

            <span class="yellow score" style="width: 15px">{{
              game.awayResult
            }}</span>
          </dd>
        </dl>
      </div>
      <div class="new_tab" style="margin-top: 10px">
        <button
          :class="{ cho: selectedTab === 'market' }"
          @click="changeTab('market')"
        >
          마켓
        </button>
        <button
          :class="{ cho: selectedTab === 'list' }"
          @click="changeTab('list')"
        >
          라이브 경기
        </button>
      </div>
      <template v-if="selectedTab === 'market'">
        <div class="categorys2" v-if="isLoading && originList">
          <button @click="selectMarket('STAR')">
            <img src="@/assets/img/ico/favor_on.png" />
          </button>
          <button @click="selectMarket(null)">전체</button>
          <button @click="selectMarket('승무패')">승무패</button>
          <button @click="selectMarket('핸디캡')">핸디캡</button>
          <button @click="selectMarket('오버언더')">오버언더</button>
          <button @click="selectMarket('ETC')">기타</button>
        </div>
        <template
          v-if="
            game.status !== '진행' ||
            game.isVisible === 'n' ||
            game.isSuspended === 'y' ||
            !originList.find((e) => e.isVisible === 'y') ||
            checkBlockGame(game)
          "
        >
          <div
            style="
              padding-top: 100px;
              text-align: center;
              color: #0d6c9a;
              min-height: 400px;
            "
          >
            현재 가능한 베팅이 없습니다.
            <br /><br /><br />
            베팅이 가능한 항목은 화면에 자동으로 나타납니다
          </div>
        </template>
        <template v-else>
          <template v-if="!selectedMarket">
            <div
              class="m-game2"
              v-for="(row, index) in marketSort(originList)"
              :key="'mobileDetailMarket' + index"
            >
              <template>
                <div
                  class="head"
                  v-if="
                    originList
                      .filter((e) => String(e.marketId) === String(row.id))
                      ?.find((f) => f.isSuspended === 'n')
                  "
                >
                  <div
                    class="tit"
                    v-if="
                      row.nameEn.indexOf('Team 1') < 0 &&
                      row.nameEn.indexOf('Team 2') < 0
                    "
                  >
                    {{ row | marketNameLsport(game) }}
                  </div>
                  <div class="tit" v-else>
                    {{ row | teamMarketName(game.homeTeam, game.awayTeam) }}
                  </div>
                  <div class="right">
                    <!--                <img v-if="stared.find(e => e.gameId === game.id &&  e.seq === row.seq && e.periodSequence === '0')"-->
                    <!--                     @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">-->
                    <!--                <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">-->
                    <span
                      @click="folderClosed(row.seq, '0')"
                      class="material-icons"
                      :class="{
                        up: closed.find(
                          (e) => e.seq === row.seq && e.period === '0'
                        ),
                        down: !closed.find(
                          (e) => e.seq === row.seq && e.period === '0'
                        ),
                      }"
                      >expand_more</span
                    >
                  </div>
                </div>
                <template
                  v-if="
                    originList
                      .filter((e) => String(e.marketId) === String(row.id))
                      ?.find((f) => f.isVisible === 'y')
                  "
                >
                  <div
                    class="body"
                    v-if="
                      !closed.find((e) => e.seq === row.seq && e.period === '0')
                    "
                  >
                    <template
                      v-for="(ro2, index2) in lists.filter(
                        (e) => String(e.marketId) === String(row.id)
                      )"
                    >
                      <template v-if="ro2.bets.length < 4">
                        <div class="item" :key="'marketFolderDetail' + index2">
                          <!--<template v-if="ro2.isSuspended === 'y' && ro2.isVisible === 'y'">-->
                          <!--  <MDetailLock></MDetailLock>-->
                          <!--  <MDetailLock-->
                          <!--      v-if="ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"-->
                          <!--  ></MDetailLock>-->
                          <!--  <MDetailLock></MDetailLock>-->
                          <!--</template>-->
                          <template v-if="checkIsSusepned(ro2)">
                            <MDetailPrice
                              :add-cart="addCart"
                              :check-cart="checkCart"
                              :bets="
                                ro2.bets.find(
                                  (e) =>
                                    e.name === '1' ||
                                    e.name === 'Over' ||
                                    e.name === '1X' ||
                                    e.name === 'Odd' ||
                                    e.name === 'Yes' ||
                                    e.name === 'Home' ||
                                    e.name === 'Team1'
                                )
                              "
                              :folder="ro2"
                              :game="game"
                              :team="game.homeTeam"
                            >
                            </MDetailPrice>
                            <MDetailPrice
                              v-if="
                                ro2.bets.find(
                                  (e) =>
                                    e.name === 'X' ||
                                    e.name === '12' ||
                                    e.name === 'NoGoal'
                                )
                              "
                              :add-cart="addCart"
                              :check-cart="checkCart"
                              :bets="
                                ro2.bets.find(
                                  (e) =>
                                    e.name === 'X' ||
                                    e.name === '12' ||
                                    e.name === 'NoGoal'
                                )
                              "
                              :folder="ro2"
                              :game="game"
                              :team="game.homeTeam"
                            >
                            </MDetailPrice>
                            <MDetailPrice
                              :add-cart="addCart"
                              :check-cart="checkCart"
                              :bets="
                                ro2.bets.find(
                                  (e) =>
                                    e.name === '2' ||
                                    e.name === 'Under' ||
                                    e.name === 'X2' ||
                                    e.name === 'Even' ||
                                    e.name === 'No' ||
                                    e.name === 'Away' ||
                                    e.name === 'Team2'
                                )
                              "
                              :folder="ro2"
                              :game="game"
                              :team="game.awayTeam"
                            >
                            </MDetailPrice>
                            <!--              <dl-->
                            <!--                  :set="home=ro2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                            <!--                  :class="{'active': checkCart(home=ro2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                            <!--                  @click="addCart(home=ro2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), ro2)"-->
                            <!--              >-->
                            <!--                <dt>-->
                            <!--                  {{ home.name | betsName }} {{ro2.folders_line | lineSet2(home.name)}}-->
                            <!--                </dt>-->
                            <!--                <dd>-->
                            <!--              <span-->
                            <!--                  v-if="home.name === 'Over'"-->
                            <!--                  class="material-icons up"-->
                            <!--              >expand_more-->
                            <!--              </span>-->
                            <!--                  {{ home.price }}-->
                            <!--                </dd>-->
                            <!--              </dl>-->
                            <!--              <dl-->
                            <!--                  v-if="ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                            <!--                  :class="{'active': checkCart(draw=ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                            <!--                  @click="addCart(draw=ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), ro2)"-->
                            <!--              >-->
                            <!--                <dt>-->
                            <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                            <!--                </dt>-->
                            <!--                <dd>-->
                            <!--                  {{ draw.price }}-->
                            <!--                </dd>-->
                            <!--              </dl>-->
                            <!--              <dl-->
                            <!--                  :set="away=ro2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                            <!--                  :class="{'active': checkCart(ro2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                            <!--                  @click="addCart(ro2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), ro2)"-->
                            <!--              >-->
                            <!--                <dt>-->
                            <!--                  {{ away.name | betsName}} {{ ro2.folders_line | lineSet2(away.name, '2')}}-->
                            <!--                </dt>-->
                            <!--                <dd>-->
                            <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                            <!--                  {{ away.price }}-->
                            <!--                </dd>-->
                            <!--              </dl>-->
                          </template>
                        </div>
                      </template>
                      <template v-else>
                        <template v-for="l in arrLenDiv(ro2.bets, 3)">
                          <!--<template v-if="ro2.isVisible === 'n' || ro2.isSuspended === 'y'">-->
                          <!--  <div class="item" :key="'CorrectScoreBets' + l">-->
                          <!--    <template  v-for="bet in ro2.bets.slice((l - 1) * 3, l * 3)">-->
                          <!--      <dl-->
                          <!--          :key="'betsDetail'+bet.id"-->
                          <!--          style="width: 25%;"-->
                          <!--      >-->
                          <!--        <dt>-->
                          <!--            {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}-->
                          <!--        </dt>-->
                          <!--        <dd>-->
                          <!--          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">-->
                          <!--        </dd>-->
                          <!--      </dl>-->
                          <!--    </template>-->
                          <!--  </div>-->
                          <!--</template>-->
                          <template v-if="checkIsSusepned(ro2)">
                            <div class="item" :key="'CorrectScoreBets' + l">
                              <template
                                v-for="bet in ro2.bets.slice(
                                  (l - 1) * 3,
                                  l * 3
                                )"
                              >
                                <template v-if="bet.status === 'opened'">
                                  <dl
                                    :class="{ active: checkCart(bet) }"
                                    @click="addCart(bet, ro2, game)"
                                    :key="'betsDetail' + bet.id"
                                    style="width: 25%"
                                  >
                                    <dt>
                                      {{ manyBetsName(bet.name) }}
                                      {{
                                        bet.line && Number(bet.line) !== 0
                                          ? bet.line
                                          : ""
                                      }}
                                    </dt>
                                    <dd>
                                      {{ bet.price }}
                                    </dd>
                                  </dl>
                                </template>
                                <template v-else>
                                  <dl
                                    :key="'betsDetail' + bet.id"
                                    style="width: 33%"
                                  >
                                    <dt>
                                      {{ manyBetsName(bet.name) }}
                                      {{
                                        bet.line && Number(bet.line) !== 0
                                          ? bet.line
                                          : ""
                                      }}
                                    </dt>
                                    <dd>
                                      <img
                                        style="width: 9px"
                                        src="@/assets/img/ico/ico_secret.png"
                                      />
                                    </dd>
                                  </dl>
                                </template>
                              </template>
                            </div>
                          </template>
                        </template>
                      </template>
                    </template>
                  </div>
                </template>
              </template>
            </div>
          </template>
          <template v-else>
            <div
              class="m-game2"
              v-for="(row, index) in marketSort(lists)"
              :key="'mobileDetailMarket' + index"
            >
              <!--  마켓시퀀스 필터링 -->
              <template>
                <div
                  class="head"
                  v-if="
                    originList
                      .filter((e) => String(e.marketId) === String(row.id))
                      ?.find((f) => f.isVisible === 'y')
                  "
                >
                  <div
                    class="tit"
                    v-if="
                      row.nameEn.indexOf('Team 1') < 0 &&
                      row.nameEn.indexOf('Team 2') < 0
                    "
                  >
                    {{ row | marketNameLsport(game) }}
                  </div>
                  <div class="tit" v-else>
                    {{ row | teamMarketName(game.homeTeam, game.awayTeam) }}
                  </div>
                  <div class="right">
                    <!--                <img v-if="stared.find(e => e.gameId === game.id &&  e.seq === row.seq && e.periodSequence === '0')"-->
                    <!--                     @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">-->
                    <!--                <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">-->
                    <span
                      @click="folderClosed(row.seq, '0')"
                      class="material-icons"
                      :class="{
                        up: closed.find(
                          (e) => e.seq === row.seq && e.period === '0'
                        ),
                        down: !closed.find(
                          (e) => e.seq === row.seq && e.period === '0'
                        ),
                      }"
                      >expand_more</span
                    >
                  </div>
                </div>
                <template
                  v-if="
                    originList
                      .filter((e) => String(e.marketId) === String(row.id))
                      ?.find((f) => f.isVisible === 'y')
                  "
                >
                  <div
                    class="body"
                    v-if="
                      !closed.find((e) => e.seq === row.seq && e.period === '0')
                    "
                  >
                    <template
                      v-for="(ro2, index2) in lists.filter(
                        (e) => String(e.marketId) === String(row.id)
                      )"
                    >
                      <template v-if="ro2.bets.length < 4">
                        <div class="item" :key="'marketFolderDetail' + index2">
                          <!--<template v-if="ro2.isSuspended === 'y' && ro2.isVisible === 'y'">-->
                          <!--  <MDetailLock></MDetailLock>-->
                          <!--  <MDetailLock-->
                          <!--      v-if="ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"-->
                          <!--  ></MDetailLock>-->
                          <!--  <MDetailLock></MDetailLock>-->
                          <!--</template>-->
                          <template v-if="checkIsSusepned(ro2)">
                            <MDetailPrice
                              :add-cart="addCart"
                              :check-cart="checkCart"
                              :bets="
                                ro2.bets.find(
                                  (e) =>
                                    e.name === '1' ||
                                    e.name === 'Over' ||
                                    e.name === '1X' ||
                                    e.name === 'Odd' ||
                                    e.name === 'Yes' ||
                                    e.name === 'Home' ||
                                    e.name === 'Team1'
                                )
                              "
                              :folder="ro2"
                              :game="game"
                              :team="game.homeTeam"
                            >
                            </MDetailPrice>
                            <MDetailPrice
                              v-if="
                                ro2.bets.find(
                                  (e) =>
                                    e.name === 'X' ||
                                    e.name === '12' ||
                                    e.name === 'NoGoal'
                                )
                              "
                              :add-cart="addCart"
                              :check-cart="checkCart"
                              :bets="
                                ro2.bets.find(
                                  (e) =>
                                    e.name === 'X' ||
                                    e.name === '12' ||
                                    e.name === 'NoGoal'
                                )
                              "
                              :folder="ro2"
                              :game="game"
                              :team="game.homeTeam"
                            >
                            </MDetailPrice>
                            <MDetailPrice
                              :add-cart="addCart"
                              :check-cart="checkCart"
                              :bets="
                                ro2.bets.find(
                                  (e) =>
                                    e.name === '2' ||
                                    e.name === 'Under' ||
                                    e.name === 'X2' ||
                                    e.name === 'Even' ||
                                    e.name === 'No' ||
                                    e.name === 'Away' ||
                                    e.name === 'Team2'
                                )
                              "
                              :folder="ro2"
                              :game="game"
                              :team="game.awayTeam"
                            >
                            </MDetailPrice>
                            <!--              <dl-->
                            <!--                  :set="home=ro2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                            <!--                  :class="{'active': checkCart(home=ro2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                            <!--                  @click="addCart(home=ro2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), ro2)"-->
                            <!--              >-->
                            <!--                <dt>-->
                            <!--                  {{ home.name | betsName }} {{ro2.folders_line | lineSet2(home.name)}}-->
                            <!--                </dt>-->
                            <!--                <dd>-->
                            <!--              <span-->
                            <!--                  v-if="home.name === 'Over'"-->
                            <!--                  class="material-icons up"-->
                            <!--              >expand_more-->
                            <!--              </span>-->
                            <!--                  {{ home.price }}-->
                            <!--                </dd>-->
                            <!--              </dl>-->
                            <!--              <dl-->
                            <!--                  v-if="ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                            <!--                  :class="{'active': checkCart(draw=ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                            <!--                  @click="addCart(draw=ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), ro2)"-->
                            <!--              >-->
                            <!--                <dt>-->
                            <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                            <!--                </dt>-->
                            <!--                <dd>-->
                            <!--                  {{ draw.price }}-->
                            <!--                </dd>-->
                            <!--              </dl>-->
                            <!--              <dl-->
                            <!--                  :set="away=ro2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                            <!--                  :class="{'active': checkCart(ro2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                            <!--                  @click="addCart(ro2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), ro2)"-->
                            <!--              >-->
                            <!--                <dt>-->
                            <!--                  {{ away.name | betsName}} {{ ro2.folders_line | lineSet2(away.name, '2')}}-->
                            <!--                </dt>-->
                            <!--                <dd>-->
                            <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                            <!--                  {{ away.price }}-->
                            <!--                </dd>-->
                            <!--              </dl>-->
                          </template>
                        </div>
                      </template>
                      <template v-else>
                        <template v-for="l in arrLenDiv(ro2.bets, 3)">
                          <!--<template v-if="ro2.isVisible === 'n' || ro2.isSuspended === 'y'">-->
                          <!--  <div class="item" :key="'CorrectScoreBets' + l">-->
                          <!--    <template  v-for="bet in ro2.bets.slice((l - 1) * 3, l * 3)">-->
                          <!--      <dl-->
                          <!--          :key="'betsDetail'+bet.id"-->
                          <!--          style="width: 25%;"-->
                          <!--      >-->
                          <!--        <dt>-->
                          <!--            {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}-->
                          <!--        </dt>-->
                          <!--        <dd>-->
                          <!--          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">-->
                          <!--        </dd>-->
                          <!--      </dl>-->
                          <!--    </template>-->
                          <!--  </div>-->
                          <!--</template>-->
                          <template v-if="checkIsSusepned(ro2)">
                            <div class="item" :key="'CorrectScoreBets' + l">
                              <template
                                v-for="bet in ro2.bets.slice(
                                  (l - 1) * 3,
                                  l * 3
                                )"
                              >
                                <template v-if="bet.isVisible === 'y'">
                                  <dl
                                    :class="{ active: checkCart(bet) }"
                                    @click="addCart(bet, ro2, game)"
                                    :key="'betsDetail' + bet.id"
                                    style="width: 25%"
                                  >
                                    <dt>
                                      {{ manyBetsName(bet.name) }}
                                      {{
                                        bet.line && Number(bet.line) !== 0
                                          ? bet.line
                                          : ""
                                      }}
                                    </dt>
                                    <dd>
                                      {{ bet.price }}
                                    </dd>
                                  </dl>
                                </template>
                                <template v-else>
                                  <dl
                                    :key="'betsDetail' + bet.id"
                                    style="width: 33%"
                                  >
                                    <dt>
                                      {{ manyBetsName(bet.name) }}
                                      {{
                                        bet.line && Number(bet.line) !== 0
                                          ? bet.line
                                          : ""
                                      }}
                                    </dt>
                                    <dd>
                                      <img
                                        style="width: 9px"
                                        src="@/assets/img/ico/ico_secret.png"
                                      />
                                    </dd>
                                  </dl>
                                </template>
                              </template>
                            </div>
                          </template>
                        </template>
                      </template>
                    </template>
                  </div>
                </template>
              </template>
            </div>
          </template>
        </template>
        <div
          style="
            text-align: center;
            color: #b7b7b7;
            margin-top: 20px;
            margin-bottom: 20px;
          "
        >
          인플레이 베팅에 표시되는 시간 표시는 지표 역할을 합니다. <br />
          회사는 점수 또는 시간과 같이 표시되는 정보의 정확성 및 최신성에 대해
          책임을 지지 않습니다.
        </div>
      </template>
      <template v-else>
        <MDetailLiveMatch></MDetailLiveMatch>
      </template>
      <MCart></MCart>
    </div>
    <div style="text-align: center; min-height: 120px" v-else>
      <Loading style="margin-top: 30px"></Loading>
    </div>
  </div>
</template>

<script>
//import SideBar from '@/components/Game/Sports/LsportInplay/SideBar2';
//import NewCart from '@/components/Betting/InplayCart'
//import SelectedGame from '@/components/Game/Sports/LsportInplay/SelectedGame'

import MCart from "@/components/Betting/LsportInplay/MInplayCart";
import MDetailPrice from "@/components/Game/Sports/LsportInplay/MDetailPrice2";
import MDetailLiveMatch from "../../../../components/Game/Sports/LsportInplay/MDetailLiveMatch";
//import MDetailLock from '@/components/Game/Sports/LsportInplay/MDetailLock'
import Loading from "@/components/Loading";
import { mapState } from "vuex";
import MSG from "@/contants/msg";
import moment from "moment/moment";
import { io } from "socket.io-client";

export default {
  name: "LsportInpDetail",
  components: {
    //NewCart,
    MCart,
    MDetailPrice,
    //MDetailLock,
    Loading,
    //SideBar,
    //SelectedGame
    MDetailLiveMatch
  },
  data: function () {
    return {
      originList: null,
      lists: null,
      selectedMarket: null,
      game: null,
      isLoading: false,
      closed: [],
      timeCheck: true,
      scoreCheck: true,
      sticky: false,
      initTime: null,
      lsport_socket: null,
      selectedTab: "market",
    };
  },
  created() {
    this.lsport_socket = io(`https://lsports.j6rgtq98rh95vq5wbqsrdacx3.xyz`);
  },
  watch: {
    game: {
      handler: function (val, newVal) {
        //경기 시간 변동체크
        if (val && newVal && val.id !== newVal.id) this.initFolder();
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    /* 해당경기의 베팅을 방지하는 설정*/
    blockSetting: function () {
      return this.$store.getters["INPLAY_SETTING"];
    },
    blockTypeSetting: function () {
      return this.$store.getters["INPLAY_BLOCK_TYPE_SETTING"];
    },
    ...mapState({
      user: (state) => state.user.user,
      isMobile: (state) => state.page.isMobile,
      cartItem: (state) => state.lsport_inplay.cart,
    }),
  },
  mounted() {
    //this.$store.dispatch('SHOW_ERR_MSG', MSG.error.socketNotConnected);
    //this.$router.push({ path: '/main'})
    setTimeout(() => {
      if (!this.$socket.connected) {
        this.$store.dispatch("SHOW_ERR_MSG", MSG.error.socketNotConnected);
        this.$socket.client.connect();
        this.initFolder();
      }
    }, 2000);
    this.initFolder();
    this.interval = setInterval(this.initFolder, 5000);
    this.interval2 = setInterval(this.checkInitTime, 1000);
    this.initTime = moment().unix();
    // this.$socket.client.on('inplayMatchStats2', this.matchStats2)
    // this.$socket.client.on('inplayStats', this.matchStats)
    // this.$socket.client.on('inplayMatchUpdate', this.matchUpdate)
    // this.$socket.client.on('inplay', this.updateData)
    this.lsport_socket.on("gameUpdate", this.gameUpdate);
    this.lsport_socket.on("oddsUpdate", this.oddsUpdate);
    this.lsport_socket.on("scoreUpdate", this.scoreUpdate);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.interval2);
  },
  methods: {
    checkInitTime() {
      const now = moment().unix();
      if (this.initTime && now - this.initTime > 180) {
        this.$store.dispatch(
          "SHOW_ERR_MSG",
          "화면 로딩후 일정시간이 지나 인플레이 데이터의 갱신이 필요합니다."
        );
        this.initFolder();
        this.$router.go(0);
        this.$store.dispatch("ALL_LSPORTL_INPLAY_CLEAR_ITEM");
      }
    },

    initFolder: function () {
      const payload = {
        gameId: this.$route.query.gid,
      };
      return this.$store
        .dispatch("GET_LSPORT_INPLAY_DETAIL", { payload })
        .then((data) => {
          const result = data.data;
          if (result.success) {
            this.game = result.payload.game;
            if (!result.payload.game)
              this.$router.push({ name: "스포츠-인플레이(엘스포츠)" });
            this.originList = result.payload.list;
            this.lists = this.originList;
            this.isLoading = true;
            this.selectMarket(this.selectedMarket);
          } else {
            console.log("인플레이 경기요청 에러", result);
            this.$store.dispatch("SHOW_ERR_MSG3", result.msg);
          }
        })
        .catch((err) => {
          this.$store.dispatch("SHOW_ERR_MSG", err.response.data.error);
        });
    },
    // 마켓선택
    selectMarket: function (market) {
      this.selectedMarket = market;
      if (!market) return (this.lists = this.originList);
      if (market === "승무패" || market === "핸디캡" || market === "오버언더") {
        return (this.lists = this.originList.filter(
          (e) => e.market.nameKr?.indexOf(market) >= 0
        ));
      }

      if (market === "ETC") {
        return (this.lists = this.originList.filter((e) => {
          if (this.game.sportSeq === 210)
            return (
              [
                11573, 11574, 11923, 12930, 11684, 11685, 11567, 11572, 11568,
                11727, 13619, 11732,
              ].indexOf(e.marketSeq) >= 0
            );
          if (this.game.sportSeq === 211)
            return (
              [11652, 11653, 11654, 11658, 11659, 11656, 11717, 11718].indexOf(
                e.marketSeq
              ) >= 0
            );
          if (this.game.sportId === 3)
            return (
              [
                11536, 16637, 12530, 12531, 12566, 12567, 12568, 13558, 14049,
              ].indexOf(e.marketSeq) >= 0
            );
          if (this.game.sportSeq === 214)
            return (
              [11522, 11540, 11543, 11675, 12105].indexOf(e.marketSeq) >= 0
            );
          if (this.game.sportSeq === 215)
            return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0;
          if (this.game.sportId === 11)
            return [13102, 13101].indexOf(e.marketSeq) >= 0;
          if (this.game.sportSeq === 267)
            return (
              [
                12646, 12648, 12653, 12812, 12827, 12777, 12788, 12799, 12813,
                12828, 12645, 12647, 12652, 12810, 12825, 12780, 12791, 12802,
                12816, 12831, 12778, 12789, 12800, 12814, 12829, 12782, 12793,
                12804, 12818, 12833, 12783, 12794, 12805, 12819, 12834, 12784,
                12795, 12806, 12820, 12835,
              ].indexOf(e.marketSeq) >= 0
            );
        }));
      }

      if (market === "STAR") {
        // console.log(this.stared);
        this.lists = [];
        this.stared.map((s) => {
          const lists = this.originList.filter(
            (l) =>
              String(s.gameId) === String(this.game.id) &&
              String(s.seq) === String(l.marketSeq) &&
              String(s.periodSequence) === String(l.periodSequence)
          );
          lists.map((folder) => this.lists.push(folder));
        });

        return;
      }

      this.lists = this.originList.filter(
        (e) => e.market.nameKr?.indexOf(market) >= 0
      );
    },
    // 마켓정렬
    marketSort: function (folders) {
      const markets = folders.map((e) => e.market);
      const arrUnique = markets.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx;
      });
      arrUnique.sort();
      return arrUnique;
    },
    // 베팅카트에 담기
    // 베팅카트에 담기
    addCart: function (bets, folders) {
      if (this.user) {
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.인플레이.active)
          return this.$store.dispatch("SHOW_ERR_MSG", MSG.error.authorization);
      } else return this.$store.dispatch("SHOW_ERR_MSG", MSG.error.isNotLogin);

      const cartItem = {
        gameId: this.game.id,
        gameSeq: this.game.seq,
        betsSeq: bets.seq,
        betsStatus: bets.status,
        betsId: bets.id,
        betsName: bets.name,
        betsUpdated: false,
        betsPrevOdds: bets.price,
        sportsId: this.game.sportId,
        foldersId: folders.id,
        foldersSeq: folders.seq,
        foldersType: folders.type,
        foldersStatus: folders.status,
        odds: bets.price,
        line: bets.line ? bets.line : null,
        leagueId: this.game.leagueId,
        leagueNameKr: this.game.league.nameKr,
        leagueNameEn: this.game.league.nameEn,
        leagueNameIcon: this.game.league.icon,
        sportNameKr: this.game.sport.nameKr,
        sportNameEn: this.game.sport.nameEn,
        sportNameIcon: this.game.sport.icon,
        homeNameKr: this.game.homeTeam.nameKr,
        homeNameEn: this.game.homeTeam.nameEn,
        awayNameKr: this.game.awayTeam.nameKr,
        awayNameEn: this.game.awayTeam.nameEn,
        marketNameKr: folders.market.nameKr,
        marketNameEn: folders.market.nameEn,
        marketsId: folders.marketId,
        startTime: this.game.startTime,
        odds1: folders.line,
        odds2: null,
        odds3: null,
        betType: "인플레이(엘스포츠)",
        detailType: null,
        currentPeriod: this.game.currentPeriod,
        currentTime: this.game.currentTime,
        homeScore: this.game.homeResult,
        awayScore: this.game.awayResult,
        penalty: null,
        maxAmount: this.game.league?.maxAmount,
      };
      cartItem.marketNameKr = folders.market.nameKr?.replace(
        "{sw}",
        this.game.sport.periodName
      );
      cartItem.marketNameEn = folders.market.nameEn?.replace(
        "{sw}",
        this.game.sport.periodName
      );

      if (this.game.sport.periodName === "반전") {
        cartItem.marketNameKr = folders.market.nameKr?.replace(
          "1{sw}",
          "전반전"
        );
        cartItem.marketNameEn = folders.market.nameEn?.replace(
          "2{sw}",
          "후반전"
        );
      }
      this.$store.dispatch("ADD_CART_LSPORT_INPLAY_ITEM", cartItem);
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets, type) {
      if (type === "보너스") {
        return null;
      } else {
        let index = this.cartItem.find((item) => item.betsSeq === bets.seq);
        return !!index;
      }
    },
    /**
     * 토글 액션
     * */
    toggleDetail: function (id) {
      this.toggle(this.openDetailBet, id);
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailBet.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = (item) => item) {
      const index = arr.findIndex((i) => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item];
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    movePage: function (page) {
      this.$router.push({ path: `/${page}` });
    },
    arrLenDiv: function (arr, div = 4) {
      return Math.floor(arr.length / div) + 1;
    },
    uniqPeriod: function (marketId) {
      const periods = this.originList
        .filter((e) => String(e.marketId) === String(marketId))
        .map((e) => e.periodSequence);
      let set = new Set(periods);
      const uniqueArr = [...set];
      uniqueArr.sort();
      return uniqueArr;
    },
    manyBetsName: function (betName) {
      let name = betName;
      if (name.indexOf("1andOver") >= 0) {
        name = name.replace(
          "1andOver",
          `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 오버`
        );
      }
      if (name.indexOf("2andOver") >= 0) {
        name = name.replace(
          "2andOver",
          `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 오버`
        );
      }
      if (name.indexOf("12andOver") >= 0) {
        name = name.replace("12andOver", "12 & 오버");
      }
      if (name.indexOf("1andUnder") >= 0) {
        name = name.replace(
          "1andUnder",
          `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 언더`
        );
      }
      if (name.indexOf("2andUnder") >= 0) {
        name = name.replace(
          "2andUnder",
          `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 언더`
        );
      }
      if (name.indexOf("12andUnder") >= 0) {
        name = name.replace("12andUnder", "12 & 언더");
      }

      if (name.indexOf("1XandOver") >= 0) {
        name = name.replace("1XandOver", "1X & 오버");
        return name;
      }
      if (name.indexOf("XandOver") >= 0) {
        name = name.replace("XandOver", "무 & 오버");
      }
      if (name.indexOf("X2andOver") >= 0) {
        name = name.replace("X2andOver", "X2 & 오버");
      }
      if (name.indexOf("1XandUnder") >= 0) {
        name = name.replace("1XandUnder", "1X & 언더");
      }
      if (name.indexOf("XAndUnder") >= 0) {
        name = name.replace("XAndUnder", "무 & 언더");
      }
      if (name.indexOf("X2andUnder") >= 0) {
        name = name.replace("X2andUnder", "X2 & 언더");
      }
      if (name.indexOf("ormore") >= 0) {
        name = name.replace("ormore", "골 또는 그이상");
      }
      if (name.indexOf("AnyOther") >= 0) {
        name = name.replace("AnyOther", "기타");
      }
      if (name.indexOf("Team1") >= 0) {
        name = name.replace(
          "Team1",
          this.game.homeTeam.nameKr || this.game.homeTeam.nameEn
        );
      }
      if (name.indexOf("Team2") >= 0) {
        name = name.replace(
          "Team2",
          this.game.awayTeam.nameKr || this.game.awayTeam.nameEn
        );
      }
      name = name.replace(":", " : ");
      return name;
    },
    folderClosed: function (seq, period) {
      const data = {
        seq,
        period,
      };
      const index = this.closed.findIndex(
        (e) => e.seq === seq && e.period === period
      );
      if (index >= 0) return this.closed.splice(index, 1);
      this.closed.push(data);
    },
    checkBlockGame: function () {
      let blockConfig = {};
      console.log(blockConfig);
      /*
      if (game.sportId === 1) {
        blockConfig = this.blockSetting['football'];
        if (game.subResult?.indexOf('HT') >= 0) return false;
        if (Number(game.currentPeriod) === 1 && 45 - Number(game.currentTime) <= Number(blockConfig.first)) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && 90 - Number(game.currentTime) <= Number(blockConfig.second)) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 2) {
        blockConfig = this.blockSetting['hockey'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 3) {
        blockConfig = this.blockSetting['basketball'];
        let remainTime = game.remainTime;
        let currentPeriod = 1;
        const time = game.subResult?.split(' ');
        if (time) {
          remainTime = `00:${time[time.length - 1]}`;
          currentPeriod = time.reduce((cnt, element) => cnt + (element.includes('(')), 0);
          // console.log(currentPeriod);
        }
        // console.log(remainTime)
        // console.log(`남은시간: ${remainTime}`, `경기제한: ${game.isSuspended} 경기노출: ${game.isVisible}`, `남은경기시간없음: ${remainTime === '00:00:00'}`);
        if (remainTime === '00:00:00'){
          if (game.isSuspended === 'y' || game.isVisible === 'n') return true;
          if (currentPeriod >= 3) return;
        } else {
          if (currentPeriod === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
            if (!remainTime) return true;
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
            if (!remainTime) return true;
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
            if (!remainTime) return true;
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
            if (!remainTime) return true;
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod > 4) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }
      }
      if (game.sportId === 5) {
        blockConfig = this.blockSetting['volleyball'];
        const name = `set${game.currentPeriod}Score`;
        const setHomeScore = game[name] ? game[name].split(':')[0] : 0;
        const setAwayScore = game[name] ? game[name].split(':')[1] : 0;
        if (Number(game.currentPeriod) === 1){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;
          if (Number(setHomeScore) >= Number(blockConfig.first) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.first) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 2){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.second) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.second) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 3){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.third) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.third) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 4){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.four) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.four) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 5){
          if (Number(setHomeScore) >= Number(blockConfig.five) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.five) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

      }
      if (game.sportId === 6) {
        blockConfig = this.blockSetting['americanFootball'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 11) {
        blockConfig = this.blockSetting['baseball'];
        const detailResult = game.subResult?.split(' ');
        const periodCnt = detailResult?.filter(e => e.indexOf('(') >= 0).length;
        if (periodCnt > Number(blockConfig.first)) return true;
      }
      */
      return false;
    },
    toggleStick: function () {
      this.sticky = !this.sticky;
    },
    // 스코어 업데이트
    scoreUpdate: function (data) {
      const result = JSON.parse(data);
      this.$store.dispatch("UPDATE_LSPORT_INPLAY_LIST_SCORE", result);

      for (const game of result) {
        if (String(game.id) === String(this.game.id)) {
          this.game.homeResult = game.homeResult;
          this.game.awayResult = game.awayResult;
          if (game.set1Score) this.game.set1Score = game.set1Score;
          if (game.set2Score) this.game.set2Score = game.set2Score;
          if (game.set3Score) this.game.set3Score = game.set3Score;
          if (game.set4Score) this.game.set4Score = game.set4Score;
          if (game.set5Score) this.game.set5Score = game.set5Score;
          if (game.set6Score) this.game.set6Score = game.set6Score;
          if (game.set7Score) this.game.set7Score = game.set7Score;
          if (game.set8Score) this.game.set8Score = game.set8Score;
          if (game.set9Score) this.game.set9Score = game.set9Score;
        }
      }
    },
    // 매치 상태 업데이트
    gameUpdate: function (data) {
      const result = JSON.parse(data);
      // console.log(results, 'match')
      for (const game of result) {
        if (String(this.game.id) === String(game.id)) {
          if (game.stauts) this.game.status = "진행";
        }
      }
    },
    oddsUpdate: function (data) {
      const result = JSON.parse(data);
      this.$store.dispatch("UPDATE_LSPORT_INPLAY_LIST_ODDS", result);

      for (const folder of result) {
        // console.log(result);
        const dbFolder = this.lists.find(
          (inFolder) => inFolder.id === folder.id
        );
        if (dbFolder) {
          dbFolder.isSuspended = folder.isSuspended;
          for (const bet of folder.bets) {
            const dbBet = dbFolder.bets.find((inBet) => inBet.id === bet.id);
            if (dbBet) {
              console.log(dbBet, bet, "디테일 배당변경");
              dbBet.price = bet.price;
              dbBet.status = bet.status;
            }
          }
        }
      }
    },
    checkIsSusepned(folder) {
      if (!folder.bets) return false;
      if (folder.bets.find((bet) => bet.isSuspended === "y")) return false;
      return true;
    },
    changeTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style scoped>
.score {
  width: 15px;
  margin-left: 5px;
}
</style>

import axios from 'axios';
import authHeader from './auth-header';
const BASE_URL = process.env.VUE_APP_API_URL;
import store from '../store'
class AuthenticationService {
  get(path) {
    return axios.get(BASE_URL + path, { headers: authHeader() });
  }

  ME(path, data) {
    return axios.post(BASE_URL + path, data, { headers: authHeader() });
  }

  post(path, data) {
    return axios
      .post(BASE_URL + path, data, { headers: authHeader() })
      .then(
        (response) => {return response},
        (error) => {
          if (error.response.status === 401) {
            store.commit('LOGOUT');
            // alert("인증이 만료되었습니다. 다시 로그인해주세요");
          }
          return Promise.reject(error);
        },
      )
      ;
  }

  put(path, data) {
    return axios.put(BASE_URL + path, data, { headers: authHeader() });
  }

  delete(path, data) {
    return axios.delete(BASE_URL + path, data, { headers: authHeader() });
  }
}
export default new AuthenticationService();

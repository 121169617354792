<template>
  <div class="cont">
    <div class="cart-item" :class="{'disable_bet': item.betsStatus === 'y'}" v-for="(item, idx) in cartItem" :key="'SingleMcartItem'+idx">
      <div class="cols">
        <div class="left">
          {{ item.homeNameKr || item.homeNameEn }}
          <span class="vs">vs</span><br>
          {{ item.awayNameKr || item.awayNameEn }}
        </div>
        <div class="right">
          <button @click="deleteItemCart(item)" class="btn-del">
            <span class="material-icons">close</span>
          </button>
        </div>
      </div>
      <div class="cols">
        <div class="left">{{ item.marketNameKr || item.marketNameEn }}</div>
        <div class="right" style="color: #506570;">({{ item.betsPrevOdds }})</div>
      </div>
      <div class="cols">
        <div class="left">
          <span class="orange">{{ item | cartBetsName }}</span>
          &nbsp;<span class="orange">({{ item.homeScore }}:{{ item.awayScore }})</span>
        </div>
        <div class="right">{{ item.odds }}</div>
      </div>
      <div class="cols">
        <div class="left">베팅금액</div>
        <div class="center"><input type="text" class="inp1" v-model="item.betAmount" @keyup="setBetAmount(item)"></div>
<!--        <div class="right"><button class="btn1" @click="maxBet">맥스</button></div>-->
      </div>
      <div class="cols">
        <div class="left">예상당첨금액</div>
        <div class="center">
          <span class="orange">{{ item.totalResult | makeComma }}</span>원
        </div>
        <!--        <div class="right"><button class="btn1" @click="maxBet">맥스</button></div>-->
      </div>
    </div>
    <div class="bet-area">
      <div class="bet-btn">
        <button @click="processBet">베팅하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import MSG from "@/contants/msg";

export default {
  name: "Single",
  props: ['clearCart', 'singleBetCartSetAmount', 'isUpdate'],
  computed:{
    levelSetting: function (){
      return this.$store.getters["LEVEL_SETTING"]
    },
    minAmount: function(){
      return Number(this.levelSetting.inplaySetting.minBetting);
    },
    maxAmount: function(){
      return Number(this.levelSetting.inplaySetting.maxBetting);
    },
    maxWinAmount: function(){
      return Number(this.levelSetting.inplaySetting.manyOther.maxWin);
    },
    maxOneFolderAmount: function(){
      return Number(this.levelSetting.inplaySetting.soloOther.maxBetting);
    },
    maxOneFolderWinAmount: function(){
      return Number(this.levelSetting.inplaySetting.soloOther.maxWin);
    },
    maxOdds: function(){
      return this.levelSetting.inplaySetting.maxOdds;
    },
    oneFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.one;
    },
    twoFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.two;
    },
    threeFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.three;
    },
    fourFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.four;
    },
    fiveFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.five;
    },
    ...mapState({
      user: state => state.user.user,
      cartItem: state => state.inplay.cart,
    }),
  },
  watch:{
    cartItem: {
      handler(val){
        const index = val.length;

        val[index-1].betAmount = 0
        val[index-1].totalResult = val[index-1].betAmount * val[index-1].odds
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    processBet: function(){
      if (this.cartItem.find(b => Number(b.odds) <= 1 )){
        this.$store.dispatch('SET_ERR_MSG', MSG.notAvailableBet);
        return;
      }
      // 최소 금액
      const minAmountCheck = this.cartItem.find(e => Number(e.betAmount) < this.minAmount);
      if (minAmountCheck) return this.$store.dispatch('SHOW_ERR_MSG', `최소 베팅금은 ${this.$options.filters.makeComma(this.minAmount)}원 입니다.`);

      // 단폴최대
      const maxAmountCheck = this.cartItem.find(e => Number(e.betAmount) > this.maxOneFolderAmount);
      if (maxAmountCheck) return this.$store.dispatch('SHOW_ERR_MSG', `단폴 최대 베팅금은 ${this.$options.filters.makeComma(this.maxOneFolderBetAmount)}원 입니다.`);

      const maxAmountCheck2 = this.cartItem.find(e => Number(e.betAmount) > e.maxAmount);
      if (maxAmountCheck2) return this.$store.dispatch('SHOW_ERR_MSG', `해당 경기 최대 베팅금은 ${this.$options.filters.makeComma(maxAmountCheck2.maxAmount)}원 입니다.`);

      // 단폴 당첨금 최대
      const maxWinCheck = this.cartItem.find(e => Number(e.totalResult) > this.maxWinAmount);
      if (maxWinCheck) return this.$store.dispatch('SHOW_ERR_MSG', `단폴 최대 당첨금은 ${this.$options.filters.makeComma(this.maxWinAmount)}원 입니다.`);

      // 싱글벳
      if (this.user.members_cash < this.totalCartAmount()) return this.$store.dispatch('SHOW_ERR_MSG', '베팅금액은 보유금액을 초과할 수 없습니다.');

      let data = {};
      if (['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.one) < 0){
        let confirmMsg = `단폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.one}% 배당 하락이 발생합니다.`;
        const oneConfirm = confirm(confirmMsg);
        if (!oneConfirm) return;
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.one, '유저설정-단폴베팅')
      } else {
        if (this.oneFolderDeduction) {
          let confirmMsg = `단폴 베팅시 ${this.oneFolderDeduction}% 배당 하락이 발생합니다.`;
          const singleConfirm = confirm(confirmMsg);
          if (!singleConfirm) return;
          this.singleBetDeduction()
        }
      }
      data.bettings = this.cartItem;
      this.$store.dispatch('SET_PROCESS', data);


      if (this.cartItem.find(b => b.betsStatus === 'y')){
        this.$store.dispatch('SET_ERR_MSG', MSG.notAvailableBet);
        this.$store.dispatch('SHOW_MODAL', 'bettingStatus');
        return;
      }
      this.$store.dispatch('INVISIBLE_ALL_MODALS')
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessInplaySingle')

      this.amount = 0;
      this.clearCart();
    },
    singleBetDeduction:function(){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = this.oneFolderDeduction;
        this.cartItem[c].penaltyReason = '단폴 베팅';
      }
    },
    deleteItemCart: function(obj){
      this.$store.dispatch('DELETE_CART_INPLAY_ITEM',obj)
    },
    setBetAmount(item){
      let result = Math.floor(item.odds * item.betAmount);
      if (this.maxOneFolderAmount < Number(item.betAmount)) {
        item.betAmount = Number(this.maxOneFolderAmount);
        item.totalResult = Math.floor(item.odds * item.betAmount);
        this.$forceUpdate();
        return this.$store.dispatch('SHOW_ERR_MSG', `단폴 최대 베팅금은 ${this.$options.filters.makeComma(this.maxOneFolderBetAmount)}원 입니다.`);
      }

      if (Number(this.maxAmount) < Number(item.betAmount)) {
        item.betAmount = Number(this.maxAmount);
        item.totalResult = Math.floor(item.odds * item.betAmount);
        this.$forceUpdate();
        return alert(`인플레이 최대 베팅금은 ${this.$options.filters.makeComma(this.maxOneFolderAmount)}원 입니다.`)
        // return this.$store.dispatch('SHOW_ERR_MSG', `인플레이 최대 베팅금은 ${this.$options.filters.makeComma(this.maxOneFolderAmount)}원 입니다.`);
      }

      if (this.maxWinAmount < result) {
        item.betAmount = Math.floor(this.maxWinAmount / item.odds);
        item.totalResult = Math.floor(item.betAmount * item.betAmount);
        this.$forceUpdate();
        return this.$store.dispatch('SHOW_ERR_MSG', `단폴 최대 당첨금은 ${this.$options.filters.makeComma(this.maxWinAmount)}원 입니다.`);
      }

      item.totalResult = result;
      this.$forceUpdate();
    },
    totalCartAmount: function(){
      let total = 0;
      for (const i in this.cartItem){
        total += Number(this.cartItem[i].betAmount)
      }
      return total;
    },
    betDeduction:function(penalty, reason){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = penalty;
        this.cartItem[c].penaltyReason = reason;
      }
    },
  }
}
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

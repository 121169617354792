import AuthenticationService from '../../services/authentication.service';
import store from '../index';

const getDefaultState = () => {
  return {
    settings : {}
  }
}

const state = getDefaultState()

const actions = {
  GET_SETTING({ commit }) {
    return AuthenticationService.post(`/setting/vikbet`)
      .then((response) => {
        const config = response.data?.payload?.settings;
        // console.log('setting', config);
        commit('SET_SETTING', config);
      })
  },
}

const mutations = {
  SET_SETTING(state, obj) {
    state.settings = obj
  },
}

const getters = {
  // 점검중설정
  MENU_VISIBLE: function(state) {
    return state.settings ? state.settings.config?.option?.siteDisplay : false;
  },
  emergency: function(state) {
    return state.settings ? state.settings.config?.option?.emergency.active : false;
  },
  siteOption1: function(state){
    return state.settings ? state.settings.config?.option?.siteOption1 : false;
  },
  siteOption2: function(state){
    return state.settings ? state.settings.config?.option?.siteOption2 : false;
  },
  // 출석 체크 문구
  chulDescription: function(state) {
    return state.settings ? state.settings.admin.chulsuk.description : '';
  },
  // 출석 상품 문구
  chulTakes: function(state) {
    return state.settings ? state.settings.admin.chulsuk.takes : null;
  },
  // 출석 체크 문구
  lottoDescription: function(state) {
    if (state.settings){
      return state.settings.admin.lotto.description;
    }
    return '';
  },
  // 베팅 후 베팅취소
  CANCEL_OPTION: function(state){
    if (state.settings){
      return state.settings.config.option.bettingOption.bettingCancel;
    }
    return null;
  },
  // 시작 전 베팅취소
  BEFORE_START_CANCEL_OPTION: function(state){
    if (state.settings){
      return state.settings.config.option.bettingOption.beforeStartCancel;
    }
    return null;
  },
  // 배당감소
  LEVEL_SETTING: function(state){
    const user = store.state.user.user;
    if (user && state.settings){
      const levelOption = state.settings.config.level.levelOption.find(e => e.level === user.members_grade)
      const prematchSetting = state.settings.config.level.levelWinBettingOptionPrematch.find(e => e.level === user.members_grade)
      const prematchEuropeanSetting = state.settings.config.level.levelWinBettingOptionPrematchEuropean.find(e => e.level === user.members_grade)
      const inplaySetting = state.settings.config.level.levelWinBettingOptionInplay.find(e => e.level === user.members_grade)
      const miniSetting = state.settings.config.level.levelWinBettingOptionMini.find(e => e.level === user.members_grade)
      const otherSetting = state.settings.config.level.levelWinBettingOptionOther.find(e => e.level === user.members_grade)
      const lotusSetting = state.settings.config.level.levelWinBettingOptionLotus.find(e => e.level === user.members_grade)

      return {
        levelOption,
        prematchSetting,
        prematchEuropeanSetting,
        inplaySetting,
        miniSetting,
        otherSetting,
        lotusSetting
      }
    }
    return null;
  },
  LOTUS_SETTING: function(state){
    return state.settings.lotusConfig
  },
  denyIp: function(state){
    return state.settings.admin.denyIp;
  },
  INPLAY_SETTING: function(state) {
    return state.settings.sportConfig.inplay.endTime;
  },
  INPLAY_BLOCK_TYPE_SETTING: function(state) {
    return state.settings.sportConfig.inplay.closeType;
  },
  BANK_SETTING: function(state) {
    return state.settings.config.bank?.filter(e => String(e.active) === 'true');
  },
  LOTTO_SETTING(state){
    return state.settings.admin.lotto
  },
  CHULSUK_SETTING(state){
    return state.settings.admin.chulsuk
  },
  BONUS_SETTING(state){
    return state.settings.config.option.bettingOption.sportsFolder.filter(e => e.active === true) || []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

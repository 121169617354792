<template>
  <section class="pc" v-if="!isMobile" style="min-height: 800px;">
    <!--    <h2 class="subTitle">실시간</h2>-->
    <div class="game_contents" style="min-height: 500px;">
      <table style="width: 86%;" >
        <tbody>
        <tr>
          <!--    왼쪽사이드바      -->
          <SideBar :check-block-game="checkBlockGame"></SideBar>
          <!-- left -->
          <td style="padding:0 10px 0 10px; width: 90%;" valign="top">
            <SelectedGame :origin-list="originList" :game="game" :add-cart="addCart" :check-cart="checkCart" :check-block-game="checkBlockGame" :block-type-setting="blockTypeSetting"></SelectedGame>
          </td>
          <td class="game_right_wrap" v-if="!sticky" valign="top" style="position: fixed; width: 10%;" >
            <NewCart :toggle-stick="toggleStick" :fixed="false"></NewCart>
          </td>
          <td class="game_right_wrap" v-if="sticky" valign="top" style="position: absolute; width: 10%;">
            <NewCart :toggle-stick="toggleStick" :fixed="false"></NewCart>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
  <div v-else>
    <div v-if="isLoading">
      <div class="header2" v-if="game">
        <div class="left">
          <router-link to="/sport/livematch" class="btn-prev"></router-link>
        </div>
        <div class="bet-h1">
          <img v-if="game.sport.icon" :src="game.sport.icon" style="width: 20px;">
          <img v-if="game.league.icon"
               :src="game.league.icon"
               style="width: 30px; height: 15px;">
          <p>
            {{ game.homeTeam | teamName }}
            <span>vs</span>
            {{ game.awayTeam | teamName }}
          </p>
        </div>
      </div>
      <div class="live-info"
           :class="{
            'baseball': game.sportId === 11,
            'hockey': game.sportId === 2,
            'basketball': game.sportId === 3,
            'volleyball': game.sportId === 5,
            'rugby': game.sportId === 6,
            'lol': game.sportId === 77
          }">
        <div class="tit">{{ game.league | leagueName }}</div>
        <dl>
          <dt v-if="game.sportId === 1">
            {{ game | currentPeriod3('period') }} {{ game.currentTime }} {{ game[`set${game.currentPeriod}Score`] }}
          </dt>
          <dt v-else>
            {{ game | currentPeriod3('period') }} {{ game[`set${game.currentPeriod}Score`] }}
          </dt>
          <!--   스코어     -->
          <dd>
            <template v-if="game.sportId === 11">
              <span v-for="l in 9" :key="'SportDetailPeriod1'+l" class="score">
                {{ l }}
              </span>
            </template>
            <template v-else-if="game.sportId === 3">
              <span v-for="l in 4" :key="'SportDetailPeriod1'+l" class="score">
                {{ l }}
              </span>
            </template>
            <template v-else>
              <span v-for="l in Number(game.currentPeriod)" :key="'SportDetailPeriod1'+l" class="score">
                {{ l }}
              </span>
            </template>
            <span class="score">T</span>
          </dd>
        </dl>
        <dl>
          <dt>
            {{ game.homeTeam | teamName }}
          </dt>
          <dd>
            <!--   스코어    -->
            <template v-if="game.sportId === 11">
              <span v-for="l in 9" :key="'SportDetailPeriod1'+l" class="score">
                {{ game[`set${l}Score`]?.split(':')[0] }}
              </span>
            </template>
            <template v-else-if="game.sportId === 3">
              <span v-for="l in 4" :key="'SportDetailPeriod1'+l" class="score">
                {{ game[`set${l}Score`]?.split(':')[0] }}
              </span>
            </template>
            <template v-else>
              <span v-for="l in Number(game.currentPeriod)" :key="'SportDetailPeriod1'+l" class="score">
                {{ game[`set${l}Score`]?.split(':')[0] }}
              </span>
            </template>

            <span class="yellow score" style="width: 15px;">{{ game.homeResult }}</span>
          </dd>
        </dl>
        <dl>
          <dt>
            {{ game.awayTeam | teamName }}
          </dt>
          <dd>
            <template v-if="game.sportId === 11">
              <span v-for="l in 9" :key="'SportDetailPeriod2'+l" class="score">
                {{ game[`set${l}Score`]?.split(':')[1] }}
              </span>
            </template>
            <template v-else-if="game.sportId === 3">
              <span v-for="l in 4" :key="'SportDetailPeriod1'+l" class="score">
                {{ game[`set${l}Score`]?.split(':')[1] }}
              </span>
            </template>
            <template v-else>
              <span v-for="l in Number(game.currentPeriod)" :key="'SportDetailPeriod1'+l" class="score">
                {{ game[`set${l}Score`]?.split(':')[1] }}
              </span>
            </template>

            <span class="yellow score" style="width: 15px;">{{ game.awayResult }}</span>
          </dd>
        </dl>
      </div>
      <div class="new_tab" style="margin-top: 10px">
          <button :class="{'cho': selectedTab === 'market'}" @click="changeTab('market')">마켓</button>
          <button :class="{'cho': selectedTab === 'list'}" @click="changeTab('list')">라이브 경기</button>
      </div>
      <template v-if="selectedTab === 'market'">
              <div class="categorys2" v-if="isLoading && originList">
        <button @click="selectMarket('STAR')">
          <img src="@/assets/img/ico/favor_on.png">
        </button>
        <button @click="selectMarket(null)">
          전체
        </button>
        <button @click="selectMarket('승무패')">
          승무패
        </button>
        <button @click="selectMarket('핸디캡')">
          핸디캡
        </button>
        <button @click="selectMarket('오버언더')">
          오버언더
        </button>
        <button @click="selectMarket('ETC')">
          기타
        </button>
      </div>
      <template v-if="game.status !== '진행' || game.isVisible === 'n' || game.isSuspended === 'y' || !originList.find(e => e.isVisible === 'y') || checkBlockGame(game)">
        <div style="padding-top: 100px; text-align: center; color: #0d6c9a; min-height: 400px;">
          현재 가능한 베팅이 없습니다.
          <br><br><br>
          베팅이 가능한 항목은 화면에 자동으로 나타납니다
        </div>
      </template>
      <template v-else>
        <template v-if="!selectedMarket">
          <div class="m-game2" v-for="(row, index) in marketSort(originList)" :key="'mobileDetailMarket'+index">
            <template v-if="!checkBlockGame(game)">
              <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
                <!--  마켓시퀀스 기간별 -->
                <template v-for="period in uniqPeriod(row.id)">
                  <template>
                    <div class="head" :key="'game'+game.seq+period"
                         v-if="originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))?.find(f => f.isVisible ==='y')">
                      <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                        {{ row | marketName(period) }}
                      </div>
                      <div class="tit" v-else>{{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}</div>
                      <div class="right">
                        <!--                    <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === period)"-->
                        <!--                         @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_on.png">-->
                        <!--                    <img v-else @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_off.png">-->
                        <span @click="folderClosed(row.seq, period)" class="material-icons" :class="{'up': closed.find(e => e.seq === e.period === period), 'down': !closed.find(e => e.seq === e.period === period)}">expand_more</span>
                      </div>
                    </div>
                    <div class="body" :key="'gameFolders'+game.seq+period" v-if="!closed.find(e => e.seq === e.period === period)">
                      <template v-for="(row2, index2) in originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))"
                      >
                        <template v-if="row2.bets.length < 4">
                          <div class="item"
                               :key="'marketFolderDetail'+index2">
                            <template v-if="row2.isSuspended === 'y' && row2.isVisible === 'y'">
                              <MDetailLock></MDetailLock>
                              <MDetailLock
                                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                              ></MDetailLock>
                              <MDetailLock></MDetailLock>
                            </template>
                            <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y'">
                              <MDetailPrice
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home' || e.name === 'Team1')"
                                  :folder="row2"
                                  :game="game"
                                  :team="game.homeTeam"
                              >
                              </MDetailPrice>
                              <MDetailPrice
                                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                  :folder="row2"
                                  :game="game"
                                  :team="game.homeTeam"
                              >
                              </MDetailPrice>
                              <MDetailPrice
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away' || e.name === 'Team2')"
                                  :folder="row2"
                                  :game="game"
                                  :team="game.awayTeam"
                              >
                              </MDetailPrice>
                              <!--              <dl-->
                              <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                              <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                              <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                              <!--              >-->
                              <!--                <dt>-->
                              <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                              <!--                </dt>-->
                              <!--                <dd>-->
                              <!--              <span-->
                              <!--                  v-if="home.name === 'Over'"-->
                              <!--                  class="material-icons up"-->
                              <!--              >expand_more-->
                              <!--              </span>-->
                              <!--                  {{ home.price }}-->
                              <!--                </dd>-->
                              <!--              </dl>-->
                              <!--              <dl-->
                              <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                              <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                              <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                              <!--              >-->
                              <!--                <dt>-->
                              <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                              <!--                </dt>-->
                              <!--                <dd>-->
                              <!--                  {{ draw.price }}-->
                              <!--                </dd>-->
                              <!--              </dl>-->
                              <!--              <dl-->
                              <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                              <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                              <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                              <!--              >-->
                              <!--                <dt>-->
                              <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                              <!--                </dt>-->
                              <!--                <dd>-->
                              <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                              <!--                  {{ away.price }}-->
                              <!--                </dd>-->
                              <!--              </dl>-->
                            </template>
                          </div>
                        </template>
                        <template v-else>
                          <template v-for="l in arrLenDiv(row2.bets, 3)">
                            <!--                            <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">-->
                            <!--                              <div class="item" :key="'CorrectScoreBets' + l">-->
                            <!--                                <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">-->
                            <!--                                  <dl-->
                            <!--                                      :key="'betsDetail'+bet.id"-->
                            <!--                                      style="width: 33%;"-->
                            <!--                                  >-->
                            <!--                                    <dt>-->
                            <!--                                      {{ manyBetsName(bet.name)}}-->
                            <!--                                    </dt>-->
                            <!--                                    <dd>-->
                            <!--                                      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">-->
                            <!--                                    </dd>-->
                            <!--                                  </dl>-->
                            <!--                                </template>-->
                            <!--                              </div>-->
                            <!--                            </template>-->
                            <template v-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                              <div class="item" :key="'CorrectScoreBets' + l">
                                <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                  <template v-if="bet.isVisible === 'y'">
                                    <dl
                                        :class="{'active': checkCart(bet)}"
                                        @click="addCart(bet, row2, game)"
                                        :key="'betsDetail'+bet.id"
                                        style="width: 33%;"
                                    >
                                      <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                      </dt>
                                      <dd>
                                        {{ bet.price }}
                                      </dd>
                                    </dl>
                                  </template>
                                  <template v-else>
                                    <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                      <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                      </dt>
                                      <dd>
                                        <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                      </dd>
                                    </dl>
                                  </template>
                                </template>
                              </div>
                            </template>
                          </template>
                        </template>
                      </template>
                    </div>
                  </template>
                </template>
              </template>
              <template v-else>
                <div class="head" v-if="originList.filter(e => String(e.marketId) === String(row.id))?.find(f => f.isVisible ==='y')">
                  <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                    {{ row | marketName }}
                  </div>
                  <div class="tit" v-else>{{ row | teamMarketName(game.homeTeam, game.awayTeam) }}</div>
                  <div class="right">
                    <!--                <img v-if="stared.find(e => e.gameId === game.id &&  e.seq === row.seq && e.periodSequence === '0')"-->
                    <!--                     @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">-->
                    <!--                <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">-->
                    <span @click="folderClosed(row.seq, '0')" class="material-icons" :class="{'up': closed.find(e => e.seq === row.seq && e.period === '0'), 'down': !closed.find(e => e.seq === row.seq && e.period === '0')}">expand_more</span>
                  </div>
                </div>
                <template v-if="originList.filter(e => String(e.marketId) === String(row.id))?.find(f => f.isVisible ==='y')">
                  <div class="body" v-if="!closed.find(e => e.seq === row.seq &&  e.period === '0')">
                    <template v-for="(row2, index2) in lists.filter(e => String(e.marketId) === String(row.id))">
                      <template v-if="row2.bets.length < 4">
                        <div class="item" :key="'marketFolderDetail'+index2">
                          <template v-if="row2.isSuspended === 'y' && row2.isVisible === 'y'">
                            <MDetailLock></MDetailLock>
                            <MDetailLock
                                v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                            ></MDetailLock>
                            <MDetailLock></MDetailLock>
                          </template>
                          <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y'">
                            <MDetailPrice
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home' || e.name === 'Team1')"
                                :folder="row2"
                                :game="game"
                                :team="game.homeTeam"
                            >
                            </MDetailPrice>
                            <MDetailPrice
                                v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                :folder="row2"
                                :game="game"
                                :team="game.homeTeam"
                            >
                            </MDetailPrice>
                            <MDetailPrice
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away' || e.name === 'Team2')"
                                :folder="row2"
                                :game="game"
                                :team="game.awayTeam"
                            >
                            </MDetailPrice>
                            <!--              <dl-->
                            <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                            <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                            <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                            <!--              >-->
                            <!--                <dt>-->
                            <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                            <!--                </dt>-->
                            <!--                <dd>-->
                            <!--              <span-->
                            <!--                  v-if="home.name === 'Over'"-->
                            <!--                  class="material-icons up"-->
                            <!--              >expand_more-->
                            <!--              </span>-->
                            <!--                  {{ home.price }}-->
                            <!--                </dd>-->
                            <!--              </dl>-->
                            <!--              <dl-->
                            <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                            <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                            <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                            <!--              >-->
                            <!--                <dt>-->
                            <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                            <!--                </dt>-->
                            <!--                <dd>-->
                            <!--                  {{ draw.price }}-->
                            <!--                </dd>-->
                            <!--              </dl>-->
                            <!--              <dl-->
                            <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                            <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                            <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                            <!--              >-->
                            <!--                <dt>-->
                            <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                            <!--                </dt>-->
                            <!--                <dd>-->
                            <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                            <!--                  {{ away.price }}-->
                            <!--                </dd>-->
                            <!--              </dl>-->
                          </template>
                        </div>
                      </template>
                      <template v-else>
                        <template v-for="l in arrLenDiv(row2.bets, 3)">
                          <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                            <div class="item" :key="'CorrectScoreBets' + l">
                              <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                <dl
                                    :key="'betsDetail'+bet.id"
                                    style="width: 33%;"
                                >
                                  <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                  </dt>
                                  <dd>
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </dd>
                                </dl>
                              </template>
                            </div>
                          </template>
                          <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                            <div class="item" :key="'CorrectScoreBets' + l">
                              <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                <template v-if="bet.isVisible === 'y'">
                                  <dl
                                      :class="{'active': checkCart(bet)}"
                                      @click="addCart(bet, row2, game)"
                                      :key="'betsDetail'+bet.id"
                                      style="width: 33%;"
                                  >
                                    <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                      <!-- {{ manyBetsName(bet.name)}} {{ bet.line ? bet.line : '' }} -->
                                    </dt>
                                    <dd>
                                      {{ bet.price }}
                                    </dd>
                                  </dl>
                                </template>
                                <template v-else>
                                  <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                    <dt>
                                      {{ manyBetsName(bet.name)}}
                                      <!-- {{ manyBetsName(bet.name)}} {{ bet.line ? bet.line : '' }} -->
                                    </dt>
                                    <dd>
                                      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                    </dd>
                                  </dl>
                                </template>
                              </template>
                            </div>
                          </template>
                        </template>
                      </template>
                    </template>

                  </div>
                </template>
              </template>
            </template>
            <template v-else>
              <template v-if="row.isCheck === 'y'">
                <!--  마켓시퀀스 필터링 -->
                <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
                  <!--  마켓시퀀스 기간별 -->
                  <template v-for="period in uniqPeriod(row.id)">
                    <template>
                      <div class="head" :key="'game'+game.seq+period"
                           v-if="originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))?.find(f => f.isVisible ==='y')">
                        <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                          {{ row | marketName(period) }}
                        </div>
                        <div class="tit" v-else>{{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}</div>
                        <div class="right">
                          <!--                    <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === period)"-->
                          <!--                         @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_on.png">-->
                          <!--                    <img v-else @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_off.png">-->
                          <span @click="folderClosed(row.seq, period)" class="material-icons" :class="{'up': closed.find(e => e.seq === e.period === period), 'down': !closed.find(e => e.seq === e.period === period)}">expand_more</span>
                        </div>
                      </div>
                      <div class="body" :key="'gameFolders'+game.seq+period" v-if="!closed.find(e => e.seq === e.period === period)">
                        <template v-for="(row2, index2) in originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))"
                        >
                          <template v-if="row2.bets.length < 4">
                            <div class="item"
                                 :key="'marketFolderDetail'+index2">
                              <template v-if="row2.isSuspended === 'y' && row2.isVisible === 'y'">
                                <MDetailLock></MDetailLock>
                                <MDetailLock
                                    v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                                ></MDetailLock>
                                <MDetailLock></MDetailLock>
                              </template>
                              <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y'">
                                <MDetailPrice
                                    :add-cart="addCart"
                                    :check-cart="checkCart"
                                    :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home' || e.name === 'Team1')"
                                    :folder="row2"
                                    :game="game"
                                    :team="game.homeTeam"
                                >
                                </MDetailPrice>
                                <MDetailPrice
                                    v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                    :add-cart="addCart"
                                    :check-cart="checkCart"
                                    :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                    :folder="row2"
                                    :game="game"
                                    :team="game.homeTeam"
                                >
                                </MDetailPrice>
                                <MDetailPrice
                                    :add-cart="addCart"
                                    :check-cart="checkCart"
                                    :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away' || e.name === 'Team2')"
                                    :folder="row2"
                                    :game="game"
                                    :team="game.awayTeam"
                                >
                                </MDetailPrice>
                                <!--              <dl-->
                                <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                                <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                                <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                                <!--              >-->
                                <!--                <dt>-->
                                <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                                <!--                </dt>-->
                                <!--                <dd>-->
                                <!--              <span-->
                                <!--                  v-if="home.name === 'Over'"-->
                                <!--                  class="material-icons up"-->
                                <!--              >expand_more-->
                                <!--              </span>-->
                                <!--                  {{ home.price }}-->
                                <!--                </dd>-->
                                <!--              </dl>-->
                                <!--              <dl-->
                                <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                                <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                                <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                                <!--              >-->
                                <!--                <dt>-->
                                <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                                <!--                </dt>-->
                                <!--                <dd>-->
                                <!--                  {{ draw.price }}-->
                                <!--                </dd>-->
                                <!--              </dl>-->
                                <!--              <dl-->
                                <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                                <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                                <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                                <!--              >-->
                                <!--                <dt>-->
                                <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                                <!--                </dt>-->
                                <!--                <dd>-->
                                <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                                <!--                  {{ away.price }}-->
                                <!--                </dd>-->
                                <!--              </dl>-->
                              </template>
                            </div>
                          </template>
                          <template v-else>
                            <template v-for="l in arrLenDiv(row2.bets, 3)">
                              <!--                            <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">-->
                              <!--                              <div class="item" :key="'CorrectScoreBets' + l">-->
                              <!--                                <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">-->
                              <!--                                  <dl-->
                              <!--                                      :key="'betsDetail'+bet.id"-->
                              <!--                                      style="width: 33%;"-->
                              <!--                                  >-->
                              <!--                                    <dt>-->
                              <!--                                      {{ manyBetsName(bet.name)}}-->
                              <!--                                    </dt>-->
                              <!--                                    <dd>-->
                              <!--                                      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">-->
                              <!--                                    </dd>-->
                              <!--                                  </dl>-->
                              <!--                                </template>-->
                              <!--                              </div>-->
                              <!--                            </template>-->
                              <template v-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                                <div class="item" :key="'CorrectScoreBets' + l">
                                  <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                    <template v-if="bet.isVisible === 'y'">
                                      <dl
                                          :class="{'active': checkCart(bet)}"
                                          @click="addCart(bet, row2, game)"
                                          :key="'betsDetail'+bet.id"
                                          style="width: 33%;"
                                      >
                                        <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                        </dt>
                                        <dd>
                                          {{ bet.price }}
                                        </dd>
                                      </dl>
                                    </template>
                                    <template v-else>
                                      <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                        <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                        </dt>
                                        <dd>
                                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                        </dd>
                                      </dl>
                                    </template>
                                  </template>
                                </div>
                              </template>
                            </template>
                          </template>
                        </template>
                      </div>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <div class="head" v-if="originList.filter(e => String(e.marketId) === String(row.id))?.find(f => f.isVisible ==='y')">
                    <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                      {{ row | marketName }}
                    </div>
                    <div class="tit" v-else>{{ row | teamMarketName(game.homeTeam, game.awayTeam) }}</div>
                    <div class="right">
                      <!--                <img v-if="stared.find(e => e.gameId === game.id &&  e.seq === row.seq && e.periodSequence === '0')"-->
                      <!--                     @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">-->
                      <!--                <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">-->
                      <span @click="folderClosed(row.seq, '0')" class="material-icons" :class="{'up': closed.find(e => e.seq === row.seq && e.period === '0'), 'down': !closed.find(e => e.seq === row.seq && e.period === '0')}">expand_more</span>
                    </div>
                  </div>
                  <template v-if="originList.filter(e => String(e.marketId) === String(row.id))?.find(f => f.isVisible ==='y')">
                    <div class="body" v-if="!closed.find(e => e.seq === row.seq &&  e.period === '0')">
                      <template v-for="(row2, index2) in lists.filter(e => String(e.marketId) === String(row.id))">
                        <template v-if="row2.bets.length < 4">
                          <div class="item" :key="'marketFolderDetail'+index2">
                            <template v-if="row2.isSuspended === 'y' && row2.isVisible === 'y'">
                              <MDetailLock></MDetailLock>
                              <MDetailLock
                                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                              ></MDetailLock>
                              <MDetailLock></MDetailLock>
                            </template>
                            <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y'">
                              <MDetailPrice
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home' || e.name === 'Team1')"
                                  :folder="row2"
                                  :game="game"
                                  :team="game.homeTeam"
                              >
                              </MDetailPrice>
                              <MDetailPrice
                                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                  :folder="row2"
                                  :game="game"
                                  :team="game.homeTeam"
                              >
                              </MDetailPrice>
                              <MDetailPrice
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away' || e.name === 'Team2')"
                                  :folder="row2"
                                  :game="game"
                                  :team="game.awayTeam"
                              >
                              </MDetailPrice>
                              <!--              <dl-->
                              <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                              <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                              <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                              <!--              >-->
                              <!--                <dt>-->
                              <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                              <!--                </dt>-->
                              <!--                <dd>-->
                              <!--              <span-->
                              <!--                  v-if="home.name === 'Over'"-->
                              <!--                  class="material-icons up"-->
                              <!--              >expand_more-->
                              <!--              </span>-->
                              <!--                  {{ home.price }}-->
                              <!--                </dd>-->
                              <!--              </dl>-->
                              <!--              <dl-->
                              <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                              <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                              <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                              <!--              >-->
                              <!--                <dt>-->
                              <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                              <!--                </dt>-->
                              <!--                <dd>-->
                              <!--                  {{ draw.price }}-->
                              <!--                </dd>-->
                              <!--              </dl>-->
                              <!--              <dl-->
                              <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                              <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                              <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                              <!--              >-->
                              <!--                <dt>-->
                              <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                              <!--                </dt>-->
                              <!--                <dd>-->
                              <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                              <!--                  {{ away.price }}-->
                              <!--                </dd>-->
                              <!--              </dl>-->
                            </template>
                          </div>
                        </template>
                        <template v-else>
                          <template v-for="l in arrLenDiv(row2.bets, 3)">
                            <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                              <div class="item" :key="'CorrectScoreBets' + l">
                                <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                  <dl
                                      :key="'betsDetail'+bet.id"
                                      style="width: 33%;"
                                  >
                                    <dt>
                                      {{ manyBetsName(bet.name)}}
                                      <!-- {{ manyBetsName(bet.name)}} {{ bet.line ? bet.line : '' }} -->
                                    </dt>
                                    <dd>
                                      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                    </dd>
                                  </dl>
                                </template>
                              </div>
                            </template>
                            <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                              <div class="item" :key="'CorrectScoreBets' + l">
                                <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                  <template v-if="bet.isVisible === 'y'">
                                    <dl
                                        :class="{'active': checkCart(bet)}"
                                        @click="addCart(bet, row2, game)"
                                        :key="'betsDetail'+bet.id"
                                        style="width: 33%;"
                                    >
                                      <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                        <!-- {{ manyBetsName(bet.name)}} {{ bet.line ? bet.line : '' }} -->
                                      </dt>
                                      <dd>
                                        {{ bet.price }}
                                      </dd>
                                    </dl>
                                  </template>
                                  <template v-else>
                                    <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                      <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                        <!-- {{ manyBetsName(bet.name)}} {{ bet.line ? bet.line : '' }} -->
                                      </dt>
                                      <dd>
                                        <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                      </dd>
                                    </dl>
                                  </template>
                                </template>
                              </div>
                            </template>
                          </template>
                        </template>
                      </template>

                    </div>
                  </template>
                </template>
              </template>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="m-game2" v-for="(row, index) in marketSort(lists)" :key="'mobileDetailMarket'+index">
            <!--  마켓시퀀스 필터링 -->
            <template v-if="!checkBlockGame(game)">
              <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
                <!--  마켓시퀀스 기간별 -->
                <template v-for="period in uniqPeriod(row.id)">
                  <template>
                    <div class="head" :key="'game'+game.seq+period"
                         v-if="lists.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))?.find(f => f.isVisible ==='y')">
                      <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                        {{ row | marketName(period) }}
                      </div>
                      <div class="tit" v-else>{{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}</div>
                      <div class="right">
                        <!--                    <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === period)"-->
                        <!--                         @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_on.png">-->
                        <!--                    <img v-else @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_off.png">-->
                        <span @click="folderClosed(row.seq, period)" class="material-icons" :class="{'up': closed.find(e => e.seq === e.period === period), 'down': !closed.find(e => e.seq === e.period === period)}">expand_more</span>
                      </div>
                    </div>
                    <div class="body" :key="'gameFolders'+game.seq+period" v-if="!closed.find(e => e.seq === e.period === period)">
                      <template v-for="(row2, index2) in lists.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))">
                        <template v-if="row2.bets.length < 4">
                          <div class="item"
                               :key="'marketFolderDetail'+index2">
                            <template v-if="row2.isSuspended === 'y' && row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                              <MDetailLock></MDetailLock>
                              <MDetailLock
                                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                              ></MDetailLock>
                              <MDetailLock></MDetailLock>
                            </template>
                            <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                              <MDetailPrice
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home' || e.name === 'Team1')"
                                  :folder="row2"
                                  :game="game"
                                  :team="game.homeTeam"
                              >
                              </MDetailPrice>
                              <MDetailPrice
                                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                  :folder="row2"
                                  :game="game"
                                  :team="game.homeTeam"
                              >
                              </MDetailPrice>
                              <MDetailPrice
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away' || e.name === 'Team2')"
                                  :folder="row2"
                                  :game="game"
                                  :team="game.awayTeam"
                              >
                              </MDetailPrice>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="row2.bets.length > 4">
                          <template v-for="l in arrLenDiv(row2.bets, 3)">
                            <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                              <div class="item" :key="'CorrectScoreBets' + l">
                                <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                  <dl
                                      :key="'betsDetail'+bet.id"
                                      style="width: 33%;"
                                  >
                                    <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                    </dt>
                                    <dd>
                                      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                    </dd>
                                  </dl>
                                </template>
                              </div>
                            </template>
                            <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                              <div class="item" :key="'CorrectScoreBets' + l">
                                <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                  <template v-if="bet.isVisible === 'y'">
                                    <dl
                                        :class="{'active': checkCart(bet)}"
                                        @click="addCart(bet, row2, game)"
                                        :key="'betsDetail'+bet.id"
                                        style="width: 33%;"
                                    >
                                      <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                      </dt>
                                      <dd>
                                        {{ bet.price }}
                                      </dd>
                                    </dl>
                                  </template>
                                  <template v-else>
                                    <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                      <dt>
                                        {{ manyBetsName(bet.name)}} {{ bet.line ? bet.line : '' }}
                                      </dt>
                                      <dd>
                                        <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                      </dd>
                                    </dl>
                                  </template>
                                </template>
                              </div>
                            </template>
                          </template>
                        </template>
                      </template>
                    </div>
                  </template>
                </template>
              </template>
              <template v-else>
                <div class="head"
                     v-if="lists.filter(e => String(e.marketId) === String(row.id) )?.find(f => f.isVisible ==='y')"
                >
                  <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                    {{ row | marketName }}
                  </div>
                  <div class="tit" v-else>{{ row | teamMarketName(game.homeTeam, game.awayTeam) }}</div>
                  <div class="right">
                    <!--                <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === '0')"-->
                    <!--                     @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">-->
                    <!--                <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">-->
                    <span @click="folderClosed(row.seq, '0')" class="material-icons" :class="{'up': closed.find(e => e.seq === row.seq && e.period === '0'), 'down': !closed.find(e => e.seq === row.seq && e.period === '0')}">expand_more</span>
                  </div>
                </div>
                <div class="body" v-if="!closed.find(e => e.seq === row.seq && e.period === '0')">
                  <template v-for="(row2, index2) in lists.filter(e => String(e.marketId) === String(row.id))">
                    <template v-if="row2.bets.length < 4">
                      <template v-if="row.nameKr.indexOf('정확한') >= 0">
                        <template v-for="l in arrLenDiv(row2.bets, 3)">
                          <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                            <div class="item" :key="'CorrectScoreBets' + l">
                              <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                <dl
                                    :key="'betsDetail'+bet.id"
                                    style="width: 33%;"
                                >
                                  <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                  </dt>
                                  <dd>
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </dd>
                                </dl>
                              </template>
                            </div>
                          </template>
                          <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                            <div class="item" :key="'CorrectScoreBets' + l" id="i2">
                              <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                <template v-if="bet.isVisible === 'y'">
                                  <dl
                                      :class="{'active': checkCart(bet)}"
                                      @click="addCart(bet, row2, game)"
                                      :key="'betsDetail'+bet.id"
                                      style="width: 33%;"
                                  >
                                    <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                    </dt>
                                    <dd>
                                      {{ bet.price }}
                                    </dd>
                                  </dl>
                                </template>
                                <template v-else>
                                  <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                    <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line ? bet.line : '' }}
                                    </dt>
                                    <dd>
                                      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                    </dd>
                                  </dl>
                                </template>
                              </template>
                            </div>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <div class="item" :key="'marketFolderDetail'+index2">
                          <template v-if="row2.isSuspended === 'y' && row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                            <MDetailLock></MDetailLock>
                            <MDetailLock
                                v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                            ></MDetailLock>
                            <MDetailLock></MDetailLock>
                          </template>
                          <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                            <MDetailPrice
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home')"
                                :folder="row2"
                                :game="game"
                                :team="game.homeTeam"
                            >
                            </MDetailPrice>
                            <MDetailPrice
                                v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                                :folder="row2"
                                :game="game"
                                :team="game.homeTeam"
                            >
                            </MDetailPrice>
                            <MDetailPrice
                                :add-cart="addCart"
                                :check-cart="checkCart"
                                :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away')"
                                :folder="row2"
                                :game="game"
                                :team="game.awayTeam"
                            >
                            </MDetailPrice>
                            <!--              <dl-->
                            <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                            <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                            <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                            <!--              >-->
                            <!--                <dt>-->
                            <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                            <!--                </dt>-->
                            <!--                <dd>-->
                            <!--              <span-->
                            <!--                  v-if="home.name === 'Over'"-->
                            <!--                  class="material-icons up"-->
                            <!--              >expand_more-->
                            <!--              </span>-->
                            <!--                  {{ home.price }}-->
                            <!--                </dd>-->
                            <!--              </dl>-->
                            <!--              <dl-->
                            <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                            <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                            <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                            <!--              >-->
                            <!--                <dt>-->
                            <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                            <!--                </dt>-->
                            <!--                <dd>-->
                            <!--                  {{ draw.price }}-->
                            <!--                </dd>-->
                            <!--              </dl>-->
                            <!--              <dl-->
                            <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                            <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                            <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                            <!--              >-->
                            <!--                <dt>-->
                            <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                            <!--                </dt>-->
                            <!--                <dd>-->
                            <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                            <!--                  {{ away.price }}-->
                            <!--                </dd>-->
                            <!--              </dl>-->
                          </template>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <template v-for="l in arrLenDiv(row2.bets, 3)">
                        <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                          <div class="item" :key="'CorrectScoreBets' + l">
                            <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                              <dl
                                  :key="'betsDetail'+bet.id"
                                  style="width: 33%;"
                              >
                                <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                </dt>
                                <dd>
                                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                </dd>
                              </dl>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                          <div class="item" :key="'CorrectScoreBets' + l">
                            <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                              <template v-if="bet.isVisible === 'y'">
                                <dl
                                    :class="{'active': checkCart(bet)}"
                                    @click="addCart(bet, row2, game)"
                                    :key="'betsDetail'+bet.id"
                                    style="width: 33%;"
                                >
                                  <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                  </dt>
                                  <dd>
                                    {{ bet.price }}
                                  </dd>
                                </dl>
                              </template>
                              <template v-else>
                                <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                  <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                  </dt>
                                  <dd>
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </dd>
                                </dl>
                              </template>
                            </template>
                          </div>
                        </template>
                      </template>
                    </template>
                  </template>
                </div>
              </template>
            </template>
            <template v-else>
              <template v-if="row.isCheck ==='y'">
                <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
                  <!--  마켓시퀀스 기간별 -->
                  <template v-for="period in uniqPeriod(row.id)">
                    <template>
                      <div class="head" :key="'game'+game.seq+period"
                           v-if="lists.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))?.find(f => f.isVisible ==='y')">
                        <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                          {{ row | marketName(period) }}
                        </div>
                        <div class="tit" v-else>{{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}</div>
                        <div class="right">
                          <!--                    <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === period)"-->
                          <!--                         @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_on.png">-->
                          <!--                    <img v-else @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_off.png">-->
                          <span @click="folderClosed(row.seq, period)" class="material-icons" :class="{'up': closed.find(e => e.seq === e.period === period), 'down': !closed.find(e => e.seq === e.period === period)}">expand_more</span>
                        </div>
                      </div>
                      <div class="body" :key="'gameFolders'+game.seq+period" v-if="!closed.find(e => e.seq === e.period === period)">
                        <template v-for="(row2, index2) in lists.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))">
                          <template v-if="row2.bets.length < 4">
                            <div class="item"
                                 :key="'marketFolderDetail'+index2">
                              <template v-if="row2.isSuspended === 'y' && row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                                <MDetailLock></MDetailLock>
                                <MDetailLock
                                    v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                                ></MDetailLock>
                                <MDetailLock></MDetailLock>
                              </template>
                              <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                                <MDetailPrice
                                    :add-cart="addCart"
                                    :check-cart="checkCart"
                                    :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home' || e.name === 'Team1')"
                                    :folder="row2"
                                    :game="game"
                                    :team="game.homeTeam"
                                >
                                </MDetailPrice>
                                <MDetailPrice
                                    v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                    :add-cart="addCart"
                                    :check-cart="checkCart"
                                    :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                    :folder="row2"
                                    :game="game"
                                    :team="game.homeTeam"
                                >
                                </MDetailPrice>
                                <MDetailPrice
                                    :add-cart="addCart"
                                    :check-cart="checkCart"
                                    :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away' || e.name === 'Team2')"
                                    :folder="row2"
                                    :game="game"
                                    :team="game.awayTeam"
                                >
                                </MDetailPrice>
                              </template>
                            </div>
                          </template>
                          <template v-else-if="row2.bets.length > 4">
                            <template v-for="l in arrLenDiv(row2.bets, 3)">
                              <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                                <div class="item" :key="'CorrectScoreBets' + l">
                                  <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                    <dl
                                        :key="'betsDetail'+bet.id"
                                        style="width: 33%;"
                                    >
                                      <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                      </dt>
                                      <dd>
                                        <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                      </dd>
                                    </dl>
                                  </template>
                                </div>
                              </template>
                              <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                                <div class="item" :key="'CorrectScoreBets' + l">
                                  <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                    <template v-if="bet.isVisible === 'y'">
                                      <dl
                                          :class="{'active': checkCart(bet)}"
                                          @click="addCart(bet, row2, game)"
                                          :key="'betsDetail'+bet.id"
                                          style="width: 33%;"
                                      >
                                        <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                        </dt>
                                        <dd>
                                          {{ bet.price }}
                                        </dd>
                                      </dl>
                                    </template>
                                    <template v-else>
                                      <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                        <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                        </dt>
                                        <dd>
                                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                        </dd>
                                      </dl>
                                    </template>
                                  </template>
                                </div>
                              </template>
                            </template>
                          </template>
                        </template>
                      </div>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <div class="head"
                       v-if="lists.filter(e => String(e.marketId) === String(row.id) )?.find(f => f.isVisible ==='y')"
                  >
                    <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                      {{ row | marketName }}
                    </div>
                    <div class="tit" v-else>{{ row | teamMarketName(game.homeTeam, game.awayTeam) }}</div>
                    <div class="right">
                      <!--                <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === '0')"-->
                      <!--                     @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">-->
                      <!--                <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">-->
                      <span @click="folderClosed(row.seq, '0')" class="material-icons" :class="{'up': closed.find(e => e.seq === row.seq && e.period === '0'), 'down': !closed.find(e => e.seq === row.seq && e.period === '0')}">expand_more</span>
                    </div>
                  </div>
                  <div class="body" v-if="!closed.find(e => e.seq === row.seq && e.period === '0')">
                    <template v-for="(row2, index2) in lists.filter(e => String(e.marketId) === String(row.id))">
                      <template v-if="row2.bets.length < 4">
                        <template v-if="row.nameKr.indexOf('정확한') >= 0">
                          <template v-for="l in arrLenDiv(row2.bets, 3)">
                            <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                              <div class="item" :key="'CorrectScoreBets' + l">
                                <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                  <dl
                                      :key="'betsDetail'+bet.id"
                                      style="width: 33%;"
                                  >
                                    <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                    </dt>
                                    <dd>
                                      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                    </dd>
                                  </dl>
                                </template>
                              </div>
                            </template>
                            <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                              <div class="item" :key="'CorrectScoreBets' + l" id="i2">
                                <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                  <template v-if="bet.isVisible === 'y'">
                                    <dl
                                        :class="{'active': checkCart(bet)}"
                                        @click="addCart(bet, row2, game)"
                                        :key="'betsDetail'+bet.id"
                                        style="width: 33%;"
                                    >
                                      <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                      </dt>
                                      <dd>
                                        {{ bet.price }}
                                      </dd>
                                    </dl>
                                  </template>
                                  <template v-else>
                                    <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                      <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                      </dt>
                                      <dd>
                                        <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                      </dd>
                                    </dl>
                                  </template>
                                </template>
                              </div>
                            </template>
                          </template>
                        </template>
                        <template v-else>
                          <div class="item" :key="'marketFolderDetail'+index2">
                            <template v-if="row2.isSuspended === 'y' && row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                              <MDetailLock></MDetailLock>
                              <MDetailLock
                                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                              ></MDetailLock>
                              <MDetailLock></MDetailLock>
                            </template>
                            <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                              <MDetailPrice
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home')"
                                  :folder="row2"
                                  :game="game"
                                  :team="game.homeTeam"
                              >
                              </MDetailPrice>
                              <MDetailPrice
                                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                                  :folder="row2"
                                  :game="game"
                                  :team="game.homeTeam"
                              >
                              </MDetailPrice>
                              <MDetailPrice
                                  :add-cart="addCart"
                                  :check-cart="checkCart"
                                  :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away')"
                                  :folder="row2"
                                  :game="game"
                                  :team="game.awayTeam"
                              >
                              </MDetailPrice>
                              <!--              <dl-->
                              <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                              <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                              <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                              <!--              >-->
                              <!--                <dt>-->
                              <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                              <!--                </dt>-->
                              <!--                <dd>-->
                              <!--              <span-->
                              <!--                  v-if="home.name === 'Over'"-->
                              <!--                  class="material-icons up"-->
                              <!--              >expand_more-->
                              <!--              </span>-->
                              <!--                  {{ home.price }}-->
                              <!--                </dd>-->
                              <!--              </dl>-->
                              <!--              <dl-->
                              <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                              <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                              <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                              <!--              >-->
                              <!--                <dt>-->
                              <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                              <!--                </dt>-->
                              <!--                <dd>-->
                              <!--                  {{ draw.price }}-->
                              <!--                </dd>-->
                              <!--              </dl>-->
                              <!--              <dl-->
                              <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                              <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                              <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                              <!--              >-->
                              <!--                <dt>-->
                              <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                              <!--                </dt>-->
                              <!--                <dd>-->
                              <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                              <!--                  {{ away.price }}-->
                              <!--                </dd>-->
                              <!--              </dl>-->
                            </template>
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <template v-for="l in arrLenDiv(row2.bets, 3)">
                          <template v-if="row2.isVisible === 'n' || row2.isSuspended === 'y'">
                            <div class="item" :key="'CorrectScoreBets' + l">
                              <template  v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                <dl
                                    :key="'betsDetail'+bet.id"
                                    style="width: 33%;"
                                >
                                  <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                  </dt>
                                  <dd>
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </dd>
                                </dl>
                              </template>
                            </div>
                          </template>
                          <template v-else-if="row2.isVisible ==='y' && row2.isSuspended==='n'">
                            <div class="item" :key="'CorrectScoreBets' + l">
                              <template v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)">
                                <template v-if="bet.isVisible === 'y'">
                                  <dl
                                      :class="{'active': checkCart(bet)}"
                                      @click="addCart(bet, row2, game)"
                                      :key="'betsDetail'+bet.id"
                                      style="width: 33%;"
                                  >
                                    <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                    </dt>
                                    <dd>
                                      {{ bet.price }}
                                    </dd>
                                  </dl>
                                </template>
                                <template v-else>
                                  <dl :key="'betsDetail'+bet.id" style="width: 33%;">
                                    <dt>
                                      {{ manyBetsName(bet.name)}} {{ bet.line && Number(bet.line) !== 0 ? bet.line : '' }}
                                    </dt>
                                    <dd>
                                      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                    </dd>
                                  </dl>
                                </template>
                              </template>
                            </div>
                          </template>
                        </template>
                      </template>
                    </template>
                  </div>
                </template>
              </template>
            </template>
          </div>
        </template>
      </template>
      <div style="text-align: center; color: #b7b7b7; margin-top: 20px; margin-bottom: 20px;">
        인플레이 베팅에 표시되는 시간 표시는 지표 역할을 합니다. <br>
        회사는 점수 또는 시간과 같이 표시되는 정보의 정확성 및 최신성에 대해 책임을 지지 않습니다.
      </div>
      </template>
      <template v-else>
        <RenewalMobileList></RenewalMobileList>
      </template>
      <MCart></MCart>
    </div>
    <div style="text-align: center; min-height: 120px;" v-else>
      <Loading style="margin-top: 30px; "></Loading>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/Game/Sports/Inplay/SideBar2';
import NewCart from '@/components/Betting/InplayCart'
import SelectedGame from '@/components/Game/Sports/Inplay/InplaySelectedGameNew'

import MCart from '@/components/Betting/MInplayCart'
import MDetailPrice from '@/components/Game/Sports/Inplay/MDetailPrice2'
import MDetailLock from '@/components/Game/Sports/Inplay/MDetailLock'
import RenewalMobileList from '@/components/Game/Sports/Inplay/RenewalInplayMobileList'
import Loading from '@/components/Loading'
import {mapState} from "vuex";
import MSG from "@/contants/msg";
import moment from "moment/moment";

export default {
  name: "InpDetail",
  components: {
    NewCart,
    MCart,
    MDetailPrice,
    MDetailLock,
    Loading,
    SideBar,
    SelectedGame,
    RenewalMobileList
  },
  data: function(){
    return {
      originList: null,
      lists: null,
      selectedMarket: null,
      game: null,
      isLoading: false,
      closed: [],
      timeCheck: true,
      scoreCheck: true,
      sticky: false,
      initTime: null,
      selectedTab: 'market'
    }
  },
  watch: {

    game:{
      handler: function(val, newVal){
        //경기 시간 변동체크
        if (val && newVal && val.id !== newVal.id) this.initFolder();
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    /* 해당경기의 베팅을 방지하는 설정*/
    blockSetting: function(){
      return this.$store.getters['INPLAY_SETTING'];
    },
    blockTypeSetting: function(){
      return this.$store.getters['INPLAY_BLOCK_TYPE_SETTING'];
    },
    ...mapState({
      user: state => state.user.user,
      isMobile: state => state.page.isMobile,
      cartItem: state => state.inplay.cart,
    }),
  },
  mounted() {
    setTimeout(() => {
      if (!this.$socket.connected) {
        this.$store.dispatch('SHOW_ERR_MSG', MSG.error.socketNotConnected);
        this.$socket.client.connect();
        this.initFolder()
      }
    }, 2000)
    this.initFolder();
    this.interval = setInterval(this.initFolder, 5000);
    this.interval2 = setInterval(this.checkInitTime, 1000);
    this.initTime = moment().unix();
    this.$socket.client.on('inplayMatchStats2', this.matchStats2)
    this.$socket.client.on('inplayStats', this.matchStats)
    this.$socket.client.on('inplayMatchUpdate', this.matchUpdate)
    this.$socket.client.on('inplay', this.updateData)
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.interval2);
  },
  methods: {
    checkInitTime(){
      const now = moment().unix();
      if (this.initTime && now - this.initTime  > 180){
        this.$store.dispatch('SHOW_ERR_MSG', '화면 로딩후 일정시간이 지나 인플레이 데이터의 갱신이 필요합니다.');
        this.initFolder()
        this.$router.go(0)
        this.$store.dispatch('ALL_INPLAY_CLEAR_ITEM');
      }
    },

    initFolder: function () {
      const payload = {
        gameId: this.$route.query.gid
      }
      return this.$store.dispatch('GET_INPLAY_DETAIL', { payload })
          .then(data => {
            const result = data.data;
            if (result.success) {
              this.game = result.payload.game;
              if (!result.payload.game) this.$router.push({ path: '/sport/livematch'});
              this.originList = result.payload.list;
              this.lists = this.originList;
              this.isLoading = true;
              this.selectMarket(this.selectedMarket)
            } else {
              console.log('인플레이 경기요청 에러', result)
              this.$store.dispatch('SHOW_ERR_MSG3', result.msg);
            }
          }).catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          })
    },
    matchUpdate: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const matchData = results[r];
        // console.log('detailMatchUpdate', matchData);
        if (String(this.game.id) === String(matchData.Id)) {
          // console.log('thisGameUpdate');
          if (matchData.IsStarted) this.game.status = '진행';
          this.game.isVisible = matchData.IsVisible ? 'y' : 'n';
          this.game.isSuspended = matchData.IsSuspended ? 'y' : 'n';
          if (matchData.MatchStatus === 2){
            this.game.status = '종료';
          }
        }
      }
    },
    updateData: function (data) {
      const result = JSON.parse(data);
      if (!this.game) return;
      const gameMarket = result.filter(e => String(e.MatchId) === (this.game.id))
      if (gameMarket.length > 0) {
        gameMarket.map(m => {
          const folder = this.lists.find(e => String(e.id) === String(m.Id));
          if (folder) {
            folder.isVisible = m.IsVisible ? 'y' : 'n';
            folder.isSuspended = m.IsSuspended ? 'y' : 'n';
            m.Selections.map(s => {
              const bet = folder.bets.find(b => String(b.id) === String(s.Id));
              bet.price = s.Price;
              bet.isVisible = s.IsVisible ? 'y' : 'n';
              bet.isSuspended = s.IsSuspended ? 'y' : 'n';
            })
          }
        })
      }
    },
    // 경기 스코어 업데이트
    matchStats: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        if (String(result.EventId) === String(this.game.id)) {
          const score = result.Score;
          this.game.homeResult = score.split(':')[0] || 0;
          this.game.awayResult = score.split(':')[1] || 0;
          this.game.currentPeriod = result.Period;
          if (result.Set1Score) this.game.set1Score = result.Set1Score;
          if (result.Set2Score) this.game.set2Score = result.Set2Score;
          if (result.Set3Score) this.game.set3Score = result.Set3Score;
          if (result.Set4Score) this.game.set4Score = result.Set4Score;
          if (result.Set5Score) this.game.set5Score = result.Set5Score;
          if (result.Set6Score) this.game.set6Score = result.Set6Score;
          if (result.Set7Score) this.game.set7Score = result.Set7Score;
          if (result.Set8Score) this.game.set8Score = result.Set8Score;
          if (result.Set9Score) this.game.set9Score = result.Set9Score;

          if (result.RemainingTime) this.game.remainTime = result.RemainingTime;
        }
      }
    },

    // 마켓선택
    selectMarket: function (market) {
      this.selectedMarket = market;
      if (!market) return this.lists = this.originList;
      if (market === '승무패') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11557, 11558, 11575, 11649, 11930].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11603, 11604, 11611].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 3) return [11531, 11537, 11667].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11521, 11544].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11622, 11629, 11625].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [11515, 11507, 12972, 13103].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12240, 12242, 12243, 12244, 12245, 12246, 16817, 16818].indexOf(e.marketSeq) >= 0
        });
      }

      if (market === '핸디캡') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11562, 11563, 11580, 11752, 11968].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11608, 11615, 11615, 11615].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 3) return [11532, 11539, 11668].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11904, 11724, 11546].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11623, 11631, 11626].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [11516, 11509, 17055].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12651, 12775, 12786, 12797, 12808, 12823].indexOf(e.marketSeq) >= 0
        });
      }

      if (market === '오버언더') {
        this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11559, 11560, 11561, 11577, 11753, 11969, 11592, 11586].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11605, 11607, 11606, 11612, 11612, 11612, 11613, 11613, 11613, 11614, 11614, 11614].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 3) return [11533, 11534, 11535, 11662, 11663, 11664, 11669].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11726, 11723, 11720, 12104, 11545].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [11512, 11510, 11511, 13655, 11508, 17056, 13106].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12651, 12776, 12787, 12798, 12809, 12824].indexOf(e.marketSeq) >= 0
        });
      }

      if (market === 'ETC') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11573, 11574, 11923, 12930, 11684, 11685, 11567, 11572, 11568, 11727, 13619, 11732].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11652, 11653, 11654, 11658, 11659, 11656, 11717, 11718].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 3) return [11536, 16637, 12530, 12531, 12566, 12567, 12568, 13558, 14049].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11522, 11540, 11543, 11675, 12105].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [13102, 13101].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12646, 12648, 12653, 12812, 12827, 12777, 12788, 12799, 12813, 12828, 12645, 12647, 12652, 12810, 12825, 12780, 12791, 12802, 12816, 12831, 12778, 12789, 12800, 12814, 12829, 12782, 12793, 12804, 12818, 12833, 12783, 12794, 12805, 12819, 12834, 12784, 12795, 12806, 12820, 12835].indexOf(e.marketSeq) >= 0
        });
      }

      if (market === 'STAR') {
        // console.log(this.stared);
        this.lists = [];
        this.stared.map(s => {
          const lists = this.originList.filter(l => String(s.gameId) === String(this.game.id) && String(s.seq) === String(l.marketSeq) && String(s.periodSequence) === String(l.periodSequence))
          lists.map(folder => this.lists.push(folder));
        })

        return;
      }


      this.lists = this.originList.filter(e => e.market.nameKr?.indexOf(market) >= 0);
    },
    // 마켓정렬
    marketSort: function(folders){
      const markets = folders.map(e => e.market);
      const arrUnique = markets.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    // 베팅카트에 담기
    // 베팅카트에 담기
    addCart: function (bets, folders) {
      console.log('카트 담기')
      if (this.user){
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.인플레이.active) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.authorization);
      } else return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.isNotLogin);

      const cartItem = {
        gameId: this.game.id,
        gameSeq: this.game.seq,
        betsSeq: bets.seq,
        betsStatus: bets.status,
        betsId: bets.id,
        betsName: bets.name,
        betsUpdated: false,
        betsPrevOdds: bets.price,
        sportsId: this.game.sportId,
        marketsId: folders.marketId,
        foldersId: folders.id,
        foldersSeq: folders.seq,
        foldersType: folders.type,
        isCheck: folders.market.isCheck,
        odds: bets.price,
        line: bets.line ? bets.line : null,
        leagueNameKr: this.game.league.nameKr,
        leagueNameEn: this.game.league.nameEn,
        leagueNameIcon: this.game.league.icon,
        sportNameKr: this.game.sport.nameKr,
        sportNameEn: this.game.sport.nameEn,
        sportNameIcon: this.game.sport.icon,
        homeNameKr: this.game.homeTeam.nameKr,
        homeNameEn: this.game.homeTeam.nameEn,
        awayNameKr: this.game.awayTeam.nameKr,
        awayNameEn: this.game.awayTeam.nameEn,
        marketNameKr: folders.periodSequence !== '0' ? folders.market.nameKr?.replace('{sw}', folders.periodSequence) : folders.market.nameKr,
        marketNameEn: folders.periodSequence !== '0' ? folders.market.nameEn?.replace('{sw}', folders.periodSequence) : folders.market.nameEn,
        startTime: this.game.startTime,
        odds1: null,
        odds2: null,
        odds3: null,
        betType: '인플레이',
        detailType: null,
        currentPeriod: this.game.currentPeriod,
        currentTime: `${this.game.subResult}`,
        homeScore: this.game.homeResult,
        awayScore: this.game.awayResult,
        penalty: null,
        maxAmount: this.game.league.maxAmount,
      }

      if (cartItem.marketNameKr?.indexOf('{sw}') >= 0) {
        if (folders.periodSequence !== '0'){
          cartItem.marketNameKr = cartItem.marketNameKr.replace('{sw}', folders.periodSequence)
          cartItem.marketNameEn = cartItem.marketNameEn.replace('{sw}', folders.periodSequence)
        }
      }

      if (cartItem.marketNameKr?.indexOf('1팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('1팀', cartItem.homeNameKr || cartItem.homeNameEn)
      }

      if (cartItem.marketNameKr?.indexOf('2팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('2팀', cartItem.awayNameKr || cartItem.awayNameEn)
      }
      this.$store.dispatch('ADD_CART_INPLAY_ITEM', cartItem)
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets, type) {
      if (type === '보너스') {
        return null;
      } else {
        let index = this.cartItem.find(item => item.betsSeq === bets.seq)
        return !!index;
      }
    },
    /**
     * 토글 액션
     * */
    toggleDetail: function (id) {
      this.toggle(this.openDetailBet, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailBet.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    // sortMarket:function(market){
    //   this.lists = this.originList.filter(e => e.marketSeq === market)
    // }
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    arrLenDiv: function (arr, div = 4) {
      return Math.floor(arr.length / div) + 1
    },
    uniqPeriod: function (marketId) {
      const periods = this.originList.filter(e =>  String(e.marketId) === String(marketId)).map(e => e.periodSequence);
      let set = new Set(periods);
      const uniqueArr = [...set];
      uniqueArr.sort()
      return uniqueArr
    },
    addStartedMarket: function (seq, period) {
      const market = {
        gameId: this.game.id,
        seq: seq,
        periodSequence: period,
      }
      this.$store.dispatch('list/inplayStarted', market);
    },
    manyBetsName: function (betName) {
      let name = betName;
      if (name.indexOf('W1andOver') >= 0) {
        name = name.replace('W1andOver', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 오버`)
      }
      if (name.indexOf('W2andOver') >= 0) {
        name = name.replace('W2andOver', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 오버`)
      }
      if (name.indexOf('12andOver') >= 0) {
        name = name.replace('12andOver', '12 & 오버')
      }
      if (name.indexOf('W1andUnder') >= 0) {
        name = name.replace('W1andUnder', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 언더`)
      }
      if (name.indexOf('W2andUnder') >= 0) {
        name = name.replace('W2andUnder', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 언더`)
      }
      if (name.indexOf('12andUnder') >= 0) {
        name = name.replace('12andUnder', '12 & 언더')
      }

      if (name.indexOf('1XandOver') >= 0) {
        name = name.replace('1XandOver', '1X & 오버')
        return name;
      }
      if (name.indexOf('XandOver') >= 0) {
        name = name.replace('XandOver', '무 & 오버')
      }
      if (name.indexOf('X2andOver') >= 0) {
        name = name.replace('X2andOver', 'X2 & 오버')
      }
      if (name.indexOf('1XandUnder') >= 0) {
        name = name.replace('1XandUnder', '1X & 언더')
      }
      if (name.indexOf('XAndUnder') >= 0) {
        name = name.replace('XAndUnder', '무 & 언더')
      }
      if (name.indexOf('X2andUnder') >= 0) {
        name = name.replace('X2andUnder', 'X2 & 언더')
      }
      if (name.indexOf('ormore') >= 0) {
        name = name.replace('ormore', '골 또는 그이상')
      }
      if (name.indexOf('AnyOther') >= 0) {
        name = name.replace('AnyOther', '기타')
      }
      if (name.indexOf('Team1') >= 0){
        name = name.replace('Team1', this.game.homeTeam.nameKr || this.game.homeTeam.nameEn)
      }
      if (name.indexOf('Team2') >= 0){
        name = name.replace('Team2', this.game.awayTeam.nameKr || this.game.awayTeam.nameEn)
      }
      name = name.replace(':', ' : ')
      return name;
    },
    folderClosed: function(seq, period){
      const data = {
        seq,
        period
      };
      const index = this.closed.findIndex(e => e.seq === seq && e.period === period)
      if (index >= 0) return this.closed.splice(index, 1);
      this.closed.push(data);
    },
    // 스포츠별 피어리드별 타임 체크
    sportPeriodCheck: function(period){
      if (this.game.sportId === 3 && Number(period) >= 4) return false;
      return true;
    },
    checkBlockGame: function(game){
      let blockConfig = {};
      if (game.sportId === 1) {
        blockConfig = this.blockSetting['football'];
        if (game.subResult?.indexOf('HT') >= 0) return false;
        if (Number(game.currentPeriod) === 1 && 45 - Number(game.currentTime) <= Number(blockConfig.first)) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && 90 - Number(game.currentTime) <= Number(blockConfig.second)) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 2) {
        blockConfig = this.blockSetting['hockey'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 3) {
        blockConfig = this.blockSetting['basketball'];
        let remainTime = game.remainTime;
        let currentPeriod = 1;
        const time = game.subResult?.split(' ');
        if (time) {
          remainTime = `00:${time[time.length - 1]}`;
          currentPeriod = time.reduce((cnt, element) => cnt + (element.includes('(')), 0);
        }
        // console.log(remainTime)
        // console.log(`남은시간: ${remainTime}`, `경기제한: ${game.isSuspended} 경기노출: ${game.isVisible}`, `남은경기시간없음: ${remainTime === '00:00:00'}`);
        if (remainTime === '00:00:00'){
          if (game.isSuspended === 'y' || game.isVisible === 'n') return true;
          if (currentPeriod >= 3) return;
        } else {
          if (currentPeriod === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod > 4) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }
      }
      if (game.sportId === 5) {
        blockConfig = this.blockSetting['volleyball'];
        const name = `set${game.currentPeriod}Score`;
        const setHomeScore = game[name] ? game[name].split(':')[0] : 0;
        const setAwayScore = game[name] ? game[name].split(':')[1] : 0;
        if (Number(game.currentPeriod) === 1){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;
          if (Number(setHomeScore) >= Number(blockConfig.first) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.first) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 2){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.second) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.second) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 3){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.third) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.third) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 4){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.four) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.four) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 5){
          if (Number(setHomeScore) >= Number(blockConfig.five) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.five) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

      }
      if (game.sportId === 6) {
        blockConfig = this.blockSetting['americanFootball'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 11) {
        blockConfig = this.blockSetting['baseball'];
        const detailResult = game.subResult?.split(' ');
        const periodCnt = detailResult?.filter(e => e.indexOf('(') >= 0).length;
        if (periodCnt > Number(blockConfig.first)) return true;
      }

      return false;
    },
    matchStats2: function(data){
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        if (String(result.EventId) === String(this.game.id)) {
          const score = result.Score;
          // console.log('stats', result);f
          this.game.homeResult = score.split(':')[0] || 0;
          this.game.awayResult = score.split(':')[1] || 0;
          if (result.EventType !== 'period' && !result.IsTimeout) this.game.currentPeriod = result.Period;
          if (result.Set1Score) this.game.set1Score = result.Set1Score;
          if (result.Set2Score) this.game.set2Score = result.Set2Score;
          if (result.Set3Score) this.game.set3Score = result.Set3Score;
          if (result.Set4Score) this.game.set4Score = result.Set4Score;
          if (result.Set5Score) this.game.set5Score = result.Set5Score;
          if (result.Set6Score) this.game.set6Score = result.Set6Score;
          if (result.Set7Score) this.game.set7Score = result.Set7Score;
          if (result.Set8Score) this.game.set8Score = result.Set8Score;
          if (result.Set9Score) this.game.set9Score = result.Set9Score;
          // console.log(result.RemainingTime)
          if (result.RemainingTime) this.game.remainTime = result.RemainingTime;

          if (result.Info) this.game.subResult = result.Info
        }
      }
    },
    toggleStick: function(){
      this.sticky = !this.sticky;
    },
    changeTab(tab){
      this.selectedTab = tab;
    }
  }
}
</script>

<style scoped>
.score {
  width: 15px;
  margin-left: 5px;
}
</style>

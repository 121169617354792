<template>
  <section v-if="!this.$store.state.page.isMobile" style="min-height: 600px;">
    <div class="nbox game-type" style="min-height: 600px;">
      <SideRight></SideRight>
      <div class="contLeft" style="width: 830px !important; min-height: 600px;">
        <div class="gameBox">
          <iframe src="https://named.com/minigame/dhlottery/powerball/pc" width="830" height="630" scrolling="no" frameborder="0"></iframe>
        </div>

        <h3 class="tit-bet">베팅내역</h3>
        <table class="listTable03">
          <colgroup>
            <col width="100" />
            <col width="100" />
            <col width="100" />
            <col width="100" />
            <col width="100" />
            <col width="100" />
            <!--            <col width="100" />-->
          </colgroup>
          <tr>
            <th>회차</th>
            <th>베팅일시</th>
            <th>베팅</th>
            <th>베팅액</th>
            <th>적중액</th>
            <th>결과</th>
            <!--            <th>취소/삭제</th>-->
          </tr>
          <tr v-for="(row, index) in lists" :key="'minigameList' + index">
            <td>{{ row.betting_round }} 회차</td>
            <td>
              {{ row.betting_regdatetime | formatDate("MM-DD HH:mm:ss") }}
            </td>
            <td>{{ row.betting_bet_name }}</td>
            <td>{{ row.betting_bet_amount | makeComma }}</td>
            <td>{{ row.betting_payed_amount | makeComma }}</td>
            <td>
              <span
                :class="{
                  blue: row.betting_total_result === 'hit',
                  red: row.betting_total_result === 'miss',
                }"
                >{{ row.betting_total_result | bettingStatus }}</span
              >
            </td>
          </tr>
        </table>
        <Pagination
          v-if="pagination.end"
          :pagination="pagination"
          :req-data="reqData"
          :payload="payload"
        ></Pagination>
      </div>
      <div class="contRight" style="position: fixed; left: calc(59vw + 200px); width: 285px">
        <PowerballCart
          :game="game"
          :get-game="getGame"
          :check-cart="checkCart"
          :req-data="reqData"
        ></PowerballCart>
      </div>
    </div>
  </section>
  <div class="mini-game" v-else>
    <div
      class="game-wrap powerball"
      style="
        height: 450px;
        padding-left: 15px !important;
        padding-top: 40px !important;
      "
    >
      <iframe
        :src="`https://named.com/minigame/dhlottery/powerball/mobile`"
        frameborder="0"
        scrolling="no"
        width="97%" height="400"
        style="transform: scale(1.2);"
        allowtransparency="true"
      ></iframe>
    </div>
    <MCartPowerball
      :game="game"
      :check-cart="checkCart"
      :get-game="getGame"
      :req-data="reqData"
    ></MCartPowerball>
    <table class="listTable02 listTable002" style="width: 100%">
      <caption>
        배팅내역
      </caption>
      <tbody>
        <tr>
          <td>회차</td>
          <td>베팅일시</td>
          <td>베팅</td>
          <td>베팅액</td>
          <td>적중액</td>
          <td>결과</td>
        </tr>
        <tr v-for="(row, index) in lists" :key="'minigameList' + index">
          <td
            style="
              background: #fff;
              border-left: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
            "
          >
            {{ row.betting_round }} 회차
          </td>
          <td
            style="
              background: #fff;
              border-left: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
            "
          >
            {{ row.betting_regdatetime | formatDate("MM-DD HH:mm:ss") }}
          </td>
          <td
            style="
              background: #fff;
              border-left: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
            "
          >
            {{ row.betting_bet_name }}
          </td>
          <td
            style="
              background: #fff;
              border-left: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
            "
          >
            {{ row.betting_bet_amount | makeComma }}
          </td>
          <td
            style="
              background: #fff;
              border-left: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
            "
          >
            {{ row.betting_payed_amount | makeComma }}
          </td>
          <td
            style="
              background: #fff;
              border-left: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
            "
          >
            <span
              :class="{
                blue: row.betting_total_result === 'hit',
                red: row.betting_total_result === 'miss',
              }"
              >{{ row.betting_total_result | bettingStatus }}</span
            >
          </td>
        </tr>
        <!--      <tr>-->
        <!--        <td colspan="6">검색된 내역이 없습니다.</td>-->
        <!--      </tr>-->
      </tbody>
    </table>
  </div>
</template>

<script>
import SideRight from "../../../components/Game/MiniGame/SideRight";
import PowerballCart from "../../../components/Betting/DHPowerballCart";
import MCartPowerball from "../../../components/Betting/MCartDHPowerball";
import Pagination from "../../../components/Pagination03.vue";
import moment from "moment/moment";

export default {
  name: "PowerBall",
  components: {
    SideRight,
    PowerballCart,
    // SideRight,
    MCartPowerball,
    Pagination,
  },
  data: function () {
    return {
      loading: {
        isLoading: true,
        color: "#d82731",
        size: "80px",
      },
      game: null,
      lists: [],
      cartType: "minigame",
      pagination: {},
      payload: {
        page: 1,
        size: 5,
        type: '동행파워볼',
      },
    };
  },
  mounted() {
    this.checkTime();
    this.interval2 = setInterval(this.checkTime, 5000);
    this.interval = setInterval(this.reqData, 10000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.interval2);
  },
  computed: {
    user(){
      return this.$store.state.user.user;
    }
  },
  created: function () {
    alert("동행파워볼은 점검 중입니다.");
    this.$router.push({path: `/`})

    this.getGame();
    this.reqData();
  },
  watch: {
    type: function () {
      location.reload();
      window.scrollTo(0, 0);
    },
  },
  methods: {
    getGame: function () {
      this.$store.dispatch('MINI_GAME_BALL', { type: this.payload.type })
          .then((response) => {
            let data;
            if (Array.isArray(response)){
              data = response[0].data;
            } else {
              data = response.data
            }
            this.game = data.payload.game;
          })
          .catch((error) => {
            // 로그인 실패시 처리
            this.signInErrors = error.response.data
          })
    },

    reqData: function () {
      return this.$store
        .dispatch('GET_MINI_HISTORY', { payload: this.payload })
        .then((data) => {
          const result = data.data;
          this.lists = result.payload.list;
          this.pagination = result.payload.pagination;
        })
        .catch((err) => {
          alert(err.response.data.error);
        });
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets) {
      let index = this.$store.state.minigame.cart.find(
        (item) => item.betsSeq === bets.bets_seq
      );
      return !!index;
    },
    refresh: function () {
      this.initFolder();
    },
    checkTime: function() {
      const now = moment().format('HH:mm:ss')
      if(now  < '06:00:00') {
        alert("동행파워볼 미운영 시간입니다. (매일 00:00~06:00)");
        this.$router.push({path: `/`})
      }
    }
  },
};
</script>

<style scoped>
.powerball {
  height: 131px;
  margin: 0px 0px !important;
  padding: 5px 5px !important;
}
.listTable002 caption {
  background: #2e3334;
  color: #fff;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.listTable002 td {
  height: 30px;
  font-size: 11px;
  background: #d1d1d1;
  color: #000;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
#miniViewFrame {
  width: 300px;
  height: 500px;
}
</style>

import AuthenticationService from "@/services/authentication.service";


const getDefaultState = () => {
    return {
        event_popup: [],
    }
}

const state = getDefaultState()
const getters = {
    EVENT_POPUP_BG: function(state){
        let result = false;
        state.event_popup.map(p => {
            if (p.isVisible) result = true
        })
        return result;
    }
};
const mutations = {
    SET_EVENT_POPUP(state, data) {
        state.event_popup = data;
    },
}
const actions = {
    GET_POPUP({commit}, params) {
        return AuthenticationService.get('/setting/popup/vikbet', params).then((response) => {
            return commit('SET_EVENT_POPUP', response.data.payload.popups)
        })
    },
    SET_EVENT_POPUP2({commit}, params)  {
        commit('SET_EVENT_POPUP', params)
    },
}
export default {
    state,
    getters,
    actions,
    mutations
}

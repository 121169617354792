import { render, staticRenderFns } from "./MLsportCart.vue?vue&type=template&id=237ee949&scoped=true"
import script from "./MLsportCart.vue?vue&type=script&lang=js"
export * from "./MLsportCart.vue?vue&type=script&lang=js"
import style0 from "./MLsportCart.vue?vue&type=style&index=0&id=237ee949&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237ee949",
  null
  
)

export default component.exports
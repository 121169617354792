<template>
  <section v-if="!this.$store.state.page.isMobile">
    <div id="contents_wrap">
      <div class="contents">
        <div class="casis-bn" style="width: 1330px !important;">
          <Swiper>
            <Swiper-slide><img style="width: 1200px; height: 243px;" src="@/assets/img/etc/bn.jpg"></Swiper-slide>
            <Swiper-slide><img style="width: 1330px; height: 243px;" src="@/assets/img/etc/777.png"></Swiper-slide>
          </Swiper>
        </div>

        <div class="casino_left" style="padding-top: 20px;">
          <ul id="game_cat" class="smk_accordion" style="display: block;">
            <li class="acc_section acc_active">
              <div class="acc_head" @click="selectGame(null)">전체</div>
            </li>
            <li class="acc_section acc_active">
              <div class="acc_head" @click="toggleSubMenu('토큰')">토큰게임</div>
              <transition name="fade">
                <div class="acc_content"  v-show="expendedSubMenu.indexOf('토큰') >= 0" style="display: block;">
                  <ul class="cl_tabs">
                    <li v-for="(game, index) in gameList.filter(e => e.type === '토큰')" :key="'leftList'+game.type+index" :class="{'active': selectedGame === game.name }">
                      <a @click="selectGame(game)" class="get-game" data-val="12" style="color: lightgray;">{{ game.name }}</a>
                    </li>
                  </ul>
                </div>
              </transition>
            </li>
            <li class="acc_section acc_active">
              <div class="acc_head" @click="toggleSubMenu('로투스')">로투스</div>
              <transition name="fade">
                <div v-show="expendedSubMenu.indexOf('로투스') >= 0" class="acc_content" style="display: block;">
                  <ul class="cl_tabs">
                    <li v-for="(game, index) in gameList.filter(e => e.type === '로투스')" :key="'leftList'+game.type+index" :class="{'active': selectedGame === game.name }">
                      <a @click="selectGame(game)" class="get-game" data-val="12" style="color: lightgray;">{{ game.name }}</a>
                    </li>
                  </ul>
                </div>
              </transition>
            </li>
            <li class="acc_section acc_active">
              <div class="acc_head" @click="toggleSubMenu('네임드')">네임드</div>
              <transition name="fade">
                <div v-show="expendedSubMenu.indexOf('네임드') >= 0" class="acc_content" style="display: block;">
                  <ul class="cl_tabs">
                    <li v-for="(game, index) in gameList.filter(e => e.type === '네임드')" :key="'leftList'+game.type+index" :class="{'active': selectedGame === game.name }">
                      <a @click="selectGame(game)" class="get-game" data-val="12" style="color: lightgray;">{{ game.name }}</a>
                    </li>
                  </ul>
                </div>
              </transition>
            </li>
          </ul>
        </div>
        <div class="casino_right">
          <div id="l-tab1" class="left_tab_con" style="display: block;">
            <div class="tab_container">
              <div id="tab1" class="tab_content">
                <div class="casino_board_wrap">
                  <span></span>
                  <ul class="racing_board_list">
                    <li v-for="(game, index) in lists" :key="'gameList' + index" >
                      <router-link :to="game.link" data-gm-provdr="92" data-link-dsp="">
                        <div class="img racing_board_img" style="background: black; text-align: center; position: relative;">
                          <img :src="game.img"  style="width: auto; height: 195px; position: absolute; left:50%; transform: translateX(-50%);">
                          <div class="overlay">
                            <span class="expand" style="width: 125px; font-size: 20px;">Play now</span>
                          </div>
                        </div>
                      </router-link>
                      <div class="casino_board_text">
                        <p class="casino_name">{{ game.name }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div v-else>
    <div class="header2">
      <div class="left">
        <router-link to="/index" class="btn-prev"></router-link>
      </div>
      <h2>미니게임</h2>

    </div>
    <div class="casis" >
      <div class="ca-list" style="width: 100%;">
        <ul>
          <li v-for="(row, index) in miniList" :key="'gameList' + index">
            <router-link :to="row.link">
              <div class="game_thum" v-if="row.type === '네임드'" style="background:black;">
                <img style="height: 195px; width:auto;" :src="row.img">
              </div>
              <div v-else class="game_thum">
                <img style="height: 100%;" :src="row.img">
                <!-- <p>{{ row.type }} {{ row.name }}</p> -->
              </div>
              <p>{{ row.type }} {{ row.name }}</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: "Main",
  components: {
    Swiper,
    SwiperSlide
  },
  data: function(){
    return {
      expendedSubMenu: [],
      lists: [],
      gameList: [
        { type: '토큰', name: '룰렛', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/tgame_rlt.jpg', link: '/token/roulette'},
        { type: '토큰', name: '바카라', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/tgame_bcr.jpg', link: '/token/baccarat'},
        { type: '토큰', name: '하이로우', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/tgame_hl.jpg', link: '/token/highlow'},
        { type: '토큰', name: '하이로우5초', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/tgame_hl5.jpg', link: '/token/highlow5s'},
        { type: '로투스', name: '홀짝', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/%E1%84%85%E1%85%A9%E1%84%90%E1%85%AE%E1%84%89%E1%85%B3%E1%84%92%E1%85%A9%E1%86%AF%E1%84%8D%E1%85%A1%E1%86%A8.png', link: '/lotus/oe'},
        { type: '로투스', name: '바라카1', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/%E1%84%85%E1%85%A9%E1%84%90%E1%85%AE%E1%84%89%E1%85%B3%E1%84%87%E1%85%A1%E1%84%8F%E1%85%A1%E1%84%85%E1%85%A1.png', link: '/lotus/baccarat1'},
        { type: '네임드', name: 'N파워볼', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/new-powerball-2.webp', link: '/mini/npowerball'},
        { type: '네임드', name: 'N파워사다리', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/new-powerladder-2.webp', link: '/mini/npowerladder'},
        // { type: '네임드', name: '동행파워볼', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/DHPowerBall.jpg', link: '/mini/dhpowerball'},
      ],
      miniList: [
        { type: '로투스', name: '홀짝', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/%E1%84%85%E1%85%A9%E1%84%90%E1%85%AE%E1%84%89%E1%85%B3%E1%84%92%E1%85%A9%E1%86%AF%E1%84%8D%E1%85%A1%E1%86%A8.png', link: '/lotus/oe'},
        { type: '로투스', name: '바라카1', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/%E1%84%85%E1%85%A9%E1%84%90%E1%85%AE%E1%84%89%E1%85%B3%E1%84%87%E1%85%A1%E1%84%8F%E1%85%A1%E1%84%85%E1%85%A1.png', link: '/lotus/baccarat1'},
        { type: '네임드', name: 'N파워볼', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/new-powerball-2.webp', link: '/mini/npowerball'},
        { type: '네임드', name: 'N파워사다리', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/new-powerladder-2.webp', link: '/mini/npowerladder'},
        // { type: '네임드', name: '동행파워볼', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/DHPowerBall.jpg', link: '/mini/dhpowerball'},
      ],
      selectedGame: null,
    }
  },
  watch: {
    selectedGame(game){
      if (!game) return this.lists = this.gameList;
      this.lists = this.gameList.filter(e => e.name === game);
    }
  },
  mounted() {
    this.goToTop()
    this.lists = this.gameList;
  },
  methods: {
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    goToTop: function(){
      window.scrollTo(0,0);
    },
    selectGame(game){
     this.selectedGame = game?.name;
    },
    toggleSubMenu(menu){
      const index = this.expendedSubMenu.indexOf(menu);
      if (index < 0) return this.expendedSubMenu.push(menu);
      this.expendedSubMenu.splice(index, 1);
    },
  }
}
</script>

<style scoped>
#contents_wrap {float:left; width:100%; padding:50px 0 50px 0 ;}
.contents {width:1330px; margin:0px auto; min-height:600px; }
.contents_in_m20 {width:100%; float:left; margin:0 0 20px 0}

/*-------------------------------------------------------------------------------------*
 *  메인                                                                           *
 *-------------------------------------------------------------------------------------*/
.casino_left {width:330px; margin:0 30px 0 0; float:left;}
.casino_right {width:966px;float:left;}
.casino_board_wrap {width:100%; float:left; margin:0 0 20px 0}
.casino_board_list li {float:left; margin:0 40px 40px 0; width:320px;}
.casino_board_list li:nth-child(3), .racing_board_list li:nth-child(3),
.casino_board_list li:nth-child(6), .racing_board_list li:nth-child(6),
.casino_board_list li:nth-child(9), .racing_board_list li:nth-child(9) {margin:0; margin-right: 0px!important;}
.casino_board_text {width:100%;margin:15px 0 15px 0; float:left;}
.casino_name {text-align:center; color:#ffffff; }
.img {position: relative;float: left;overflow: hidden;}
.racing_board_img:hover {
  box-shadow: 0 0 50px orange, 0 0 10px #ffff, 0 0 10px #ffffff;
  transform: scale(1.08);
  transition: all 120ms ease-out;
  border-color: #FFF;
  border-radius: 0px;
}
.overlay {display: block;position: absolute;z-index: 20;background: rgba(0, 0, 0, 0.8);overflow: hidden;-webkit-transition: all 0.5s;-moz-transition: all 0.5s;-o-transition: all 0.5s;transition: all 0.5s;}
.expand {display: block;position: absolute;z-index: 100;width: 30px;height: 30px;border: solid 3px #fff;text-align: center;color: #fff;line-height: 30px;font-weight: 700;font-size: 30px;-webkit-border-radius: 30px;-moz-border-radius: 30px;-ms-border-radius: 30px;-o-border-radius: 30px;border-radius: 30px;}
.overlay {bottom: 0;left: 0;right: 0;width: 100%;height: 0;}
.overlay .expand {left: 0;right: 0;bottom: 55%;margin: 0 auto -30px auto;}
.img:hover .overlay {height: 100%;}


/*casino_left smk_accordion*/
.casino_left > .smk_accordion {
  float:right!important;
}
.casino_left > .smk_accordion .acc_section .acc_head {
  position: relative;
  background: #2d3232;
  cursor: pointer;
  text-indent: 10px;
  font-size: 12px;
  color: #8a8c8d;
  font-family: "Nanum Gothic", 굴림, 돋음, Dotum, Arial, Helvetica, sans-serif;
  width: 240px;
  padding: 0 0 0 20px;
  margin: 0 0 1px 0;
  height: 40px;
  background: #262a2b;
  line-height: 40px;
}

.casino_left > .smk_accordion li {
  margin: 0 0 1px 0!important;
}

.cl_tabs{
  width: 100%;
  /*float: left;*/
}

.cl_tabs li a {
  width: 100%;
  display: inline-block;
}

.cl_tabs li {
  width: 240px;
  padding: 0 0 0 20px;
  margin: 0 0 1px 0;
  height: 40px;
  /*background: #262a2b;*/
  line-height: 40px;
  background-color: #000000;
  text-indent: 15px;
}

.cl_tabs li:hover {
  background: #2a2e2f;
}

.cl_tabs li.active a, .casino_left > .smk_accordion li.active.ntabs  .acc_head{
  color:  white;
}

.cl_tabs li.active, .casino_left > .smk_accordion li.active.ntabs .acc_head {
  background: orange !important;
}

.tab_container {clear: both; float: left; width: 100%;}
.tab_content {padding: 20px 0 0 0;}

.racing_board_list li {float:left; margin:0 15px 0 0; width:30%;}
.racing_board_img {width:290px; height:195px; clear:both;}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
</style>

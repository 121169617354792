<template>
  <div v-if="this.$store.state.page.isMobile">
    <div class="quick-cart" @click="toggleCart" v-if="cartItem.length > 0">
      <button class="btn-cart"><span>{{ cartItem.length }}</span></button>
    </div>
    <transition name="fade">
      <div class="modal fade" :class="{'in': isView }" id="modalCart" v-if="isView" style="display: block;">
        <div class="modal-dialog" >
          <div class="modal-content">
            <div class="modal-body">
              <div class="modal-cart">
                <div class="close">
                  <button @click="$store.dispatch('INVISIBLE_ALL_MODALS')" class="btn-close" data-dismiss="modal"></button></div>
                <div class="box1">
                  <p>배팅 슬립</p>
<!--                  <p>배당변경 자동적용</p>-->
<!--                  <div>-->
<!--                    <label><input type="checkbox" class="switch" :checked="isUpdate" @click="isUpdate = !isUpdate"><span><em></em></span></label>-->
<!--                  </div>-->
                </div>
                <div class="tab-box">
                  <div class="btns">
                    <button :class="{'active': isSingle}" @click="changeSingle(true)">싱글</button>
                    <button :class="{'active': !isSingle}" @click="changeSingle(false)">다폴더</button>
                  </div>
                  <button @click="clearCart" class="btn-trash"></button>
                </div>
                <div class="body">
                  <div class="tab-cont" v-if="cartItem.length === 0">
                    베팅을 원하시면 <br>
                    원하시는 경기의 배당을 선택해 주세요.
                  </div>
                  <div class="tab-cont" v-else-if="cartItem.length > 0">
                    <MSingle v-if="isSingle" :clear-cart="clearCart" :single-bet-cart-set-amount="singleBetCartSetAmount" :is-update="isUpdate"></MSingle>
                    <MMulti v-else :clear-cart="clearCart" :is-update="isUpdate"></MMulti>                    <!-- // 다폴더 -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MSingle from './MInplaySingle'
import MMulti from './MInplayMulti'
import {mapState} from "vuex";

export default {
  name: "MCart",
  components: {
    MSingle,
    MMulti
  },
  data: function(){
    return {
      // 베팅금액
      isSingle: false,
      process: false,
      complete: false,
      upHere: false,
      isUpdate: true,
    }
  },
  mounted: function () {
    this.$socket.client.on('inplayStats', this.updateCartItemGameScore)
    this.$socket.client.on('inplay', this.updateCartItemMarket)
  },
  beforeDestroy: function () {
    this.$socket.client.off('inplayStats');
    this.$socket.client.off('stats')
  },
  computed: {
    levelSetting: function (){
      return this.$store.getters["LEVEL_SETTING"]
    },
    ...mapState({
      isView: state => state.modals.cart,
      user: state => state.user.user,
      cartItem: state => state.inplay.cart,
    }),
  },
  watch:{
    isSingle: function(val){
      if (val){
        this.singleBetCartSetAmount()
      }
    },
    cartItem: {
      handler(val){
        if (val.length === 1) this.isSingle = true;
        if (val.length > 1) this.isSingle = false;
      },
      immediate: true
    }
  },
  methods: {
    clearCart: function (){
      return this.$store.dispatch('ALL_INPLAY_CLEAR_ITEM')
    },
    closeCart: function (){
      return this.$store.dispatch('INVISIBLE_ALL_MODALS')
    },
    changeSingle: function(is){
      this.isSingle = is;
    },
    singleBetCartSetAmount(){
      for (const c in this.cartItem){
        this.cartItem[c].betAmount = 0;
        this.cartItem[c].totalResult = this.cartItem[c].betAmount * this.cartItem[c].odds;
      }
    },
    changeProcess: function(){
      this.process = !this.process;
    },
    changeComplete: function(){
      this.complete = !this.complete;
    },
    toggleCart: function(){
      this.$store.dispatch('SHOW_MODAL', 'cart')
    },
    updateCartItemMarket: function(data){
      // 마켓 업데이트
      const jsonData = JSON.parse(data);
      for (const g in jsonData){
        const gameData = jsonData[g];
        const item = this.$store.state.inplay.cart.find(i => String(i.foldersId) === String(gameData.Id));
        if (!gameData.IsVisible) {
          const cartIndex = this.$store.state.inplay.cart.findIndex(i => String(i.foldersId) === String(gameData.Id));
          if (cartIndex >= 0) this.$store.state.inplay.cart.splice(cartIndex, 1);
        }
        if (item){
          console.log(gameData.IsVisible, '폴더 VISIBLE', gameData.IsSuspended, '폴더 SUSPENDED');
          item.betStatus = gameData.IsSuspended ? 'y' : 'n';
        }
        gameData.Selections.map(selection => {
          this.$store.state.inplay.cart.map(it => {
            if (String(it.betsId) === String(selection.Id)){
              if (Number(selection.Price) === 0) {
                console.log(selection.Price, '배당 조정중');
                it.betStatus = 'y';
              } else {
                console.log(selection.Price, '배당변경');
                it.betsUpdated = true;
                it.odds = selection.Price;
              }
            }
          })
        })
      }
    },
    // updateCartItemGame: function(data){
    //   const jsonData = JSON.parse(data);
    //   console.log(jsonData, 'match');
    // },
    updateCartItemGameScore: function(data){
      // 카트내 스코업데이트
      const jsonData = JSON.parse(data);
      for (const b in jsonData){
        const gameData = jsonData[b];
        const item = this.$store.state.inplay.cart.find(i => String(i.gameId) === String(gameData.EventId));
        if (item) {
          const homeScore = gameData.Score.split(':')[0];
          const awayScore = gameData.Score.split(':')[1];
          item.homeScore = homeScore;
          item.awayScore = awayScore;
          item.currentPeriod = gameData.Period;
          item.currentTime = gameData.Info;
        }
      }
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
